import React, { useEffect, useState } from "react"
import { Card, Drawer, Pagination, Modal, notification, Input, Form, Select, DatePicker, Upload, Popconfirm, Row, Col, Checkbox, Spin, Radio } from "antd";
import { Typography, Button as UIButton, Grid } from "@mui/material";
import { EditOutlined, EyeOutlined, FilterOutlined, SearchOutlined, ImportOutlined, UploadOutlined, DeleteOutlined, QuestionCircleOutlined, LoadingOutlined, ExportOutlined } from "@ant-design/icons";
import classes from "./LoadingData.module.scss";
import { Link, useNavigate } from "react-router-dom";
import unloadImgBlack from "../../../assets/images/MenuIcon/unloadblack.svg";
import unloadImgWhite from "../../../assets/images/MenuIcon/unloadwhite.svg";
import petrolPumpIconBlack from "../../../assets/images/MenuIcon/hsddetailblack.svg";
import petrolPumpIconWhite from "../../../assets/images/MenuIcon/hsddetailwhite.svg";
import revokeLoadingIcon from "../../../assets/images/MenuIcon/revoke.svg";
import AppHelper from "../../../helpers/AppHelper";
import constantsArr from "../../../constants/global-constants";
import cancelledIconRed from "../../../assets/images/MenuIcon/cancelledIconRed.svg";
import cancelledIconWhite from "../../../assets/images/MenuIcon/cancelledIconWhite.svg";
import dayjs from "dayjs";
import importLoadingSampleFile from '../../../assets/import-samples/loading_import_sample_file.xlsx'
import totalCountImg from "../../../assets/images/HeaderIcons/totalcount.svg"
import cancelImg from "../../../assets/images/HeaderIcons/Cancel.svg"
import pendingUnloadngImg from "../../../assets/images/HeaderIcons/pendingunload.svg"
import pendingHsdImg from "../../../assets/images/HeaderIcons/pendinghsd.svg"
import Search from "antd/es/input/Search";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

export const LoadingData = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(document.location.search);
    const [loading, setLoading] = useState(true);
    const [agencyData, setAgencyData] = useState([]);
    const [showPagination, setShowPagination] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPagePagination, setCurrentPagePagination] = useState(params.get('currentPage') ?? 1)
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const [openCancelLoadingPopUp, setOpenCancelLoadingPopUp] = useState(false);
    const [openFilterSidebar, setOpenFilterSidebar] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText] = useState('If you choose to cancel this loading, you won\'t be able to undo the action.');
    const [showCancelLoadingPopup, setShowCancelLoadingPopup] = useState(false)
    const [cancelLoadingId, setCancelLoadingId] = useState(null)
    const [loadingFilterForm] = Form.useForm()
    const [agencies, setAgencies] = useState([]);
    const [loadingAgencyDropDown, setLoadingAgencyDropDown] = useState([]);
    const [transportationAgencyDropDown, setTransportationAgencyDropDown] = useState([]);
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const [vehicleLists, setVehicleLists] = useState([]);
    const [vehicleDropDown, setVehicleDropDown] = useState([]);
    const [currentPage, pageSize] = [params.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE, params.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE];
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [uploadedFile, setUploadedFile] = useState({})
    const maxUploadedImportFileSize = constantsArr.MAX_IMPORT_EXCEL_FILE_SIZE_MB * 1024 * 1024
    const allowedImportFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    const { TextArea } = Input
    const { RangePicker } = DatePicker
    const [excelImportErrors, setExcelImportErrors] = useState({})
    const [isModalOpenExcelErrors, setIsModalOpenExcelErrors] = useState(false)
    const [cancelledCheckBoxFilter, setCancelledCheckBoxFilter] = useState(false)
    const [isHSDDONECheckBoxFilter, setHSDDONECheckBoxFilter] = useState(false)
    const [sortSpinning, setSortSpinning] = useState(false);
    const sortUpdatedAt = params.get('sort') ?? null;
    const [currentDefaultValueCancelledPendingUnload, setCurrentDefaultValueCancelledPendingUnload] = useState(null)
    const [currentDefaultValuePendingHSDTaken, setCurrentDefaultValuePendingHSDTaken] = useState(null);
    const [hideDeleteIcon, setHideDeleteIcon] = useState(false); // State to manage delete icon visibility
    const [isGeneratedEntriesModalOpen, setGeneratedEntriesIsModalOpen] = useState(false);
    const [modelItem, setModelItem] = useState({});
    const sessionLoadingFilterValues = AppHelper.decryptText(sessionStorage.getItem('loadings_schema_filter_form_values'))
    const sessionChallanNumberSearchValue = AppHelper.decryptText(sessionStorage.getItem('loadings_schema_challan_number_search_value'))
    const sessionSortOrderValue = AppHelper.decryptText(sessionStorage.getItem('loadings_schema_current_sort_order_value'))
    const dateFormat = "DD-MM-YYYY";
    const dateFormatDayjsConversion = constantsArr.GLOBAL_DATE_FORMAT_DAYJS;
    const [startDateFilterFormDateRange, setStartDateFilterFormDateRange] = useState(null)
    const [endDateFilterFormDateRange, setEndDateFilterFormDateRange] = useState(null)
    const [searchChallanNumberValue, setSearchChallanNumberValue] = useState('')
    const [blankRateLoadingChecked, setBlankRateLoadingChecked] = useState(false)
    const [blankRateTransportingChecked, setBlankRateTransportingChecked] = useState(false)
    const exportPageSize = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE
    const props = {
        name: 'file',
        action: false,
        beforeUpload: file => checkBeforeUpload(file),
        maxCount: 1,
        onRemove: () => {
            setUploadedFile({});
        },
        uploadedFile
    };

    const changePageNumber = (currentPage, pageSize) => {
        // check page no is displaying in url then set that page no otherwise default 1
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        loadAllLoadings(sessionSortOrderValue ?? sortUpdatedAt, currentPage, pageSize, sessionLoadingFilterValues);
    };

    const redirectToCreateLoading = () => navigate("/loadings/create");

    const showModal = () => setOpenCancelLoadingPopUp(true);

    const handleModelExcelErrorsCancel = () => setIsModalOpenExcelErrors(false)

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleOk = async () => {
        let inputtedReason = document.getElementsByName('cancelLoadingReason')[0].value
        if (inputtedReason?.trim().length === 0) {
            notification.error({
                message: `Reason cannot be empty`,
                duration: 1.5,
            });
            return null
        }
        setConfirmLoading(true);
        let reasonDescription = document?.getElementsByName('cancelLoadingReason')[0]?.value
        let loadId = cancelLoadingId
        let cancelDetails = {
            loadId: loadId,
            reason: reasonDescription
        }
        const response = await AppHelper.cancelLoading(cancelDetails);
        if (response?.data?.id) {
            notification.success({
                message: "Success",
                description: "Loading has been cancelled successfully.",
                duration: 3,
            });
            setOpenCancelLoadingPopUp(false);
            setConfirmLoading(false);
            loadAllLoadings(sessionSortOrderValue ?? sortUpdatedAt, currentPagePagination, pageSize, sessionLoadingFilterValues);
            loadingFilterForm.resetFields()
            setRangePickerStartDateValue("")
            setRangePickerEndDateValue("")
            setHSDDONECheckBoxFilter(false)
        } else {
            notification.error({
                message: `Error: occured while loading cancellation`,
                description: response?.error,
                duration: 3,
            });
            setConfirmLoading(false);
        }

    };

    const handleChangeBlankRateLoadingCheckbox = (e) => {
        setBlankRateLoadingChecked(e.target.checked)
    }

    const handleChangeBlankRateTransportationCheckbox = (e) => {
        setBlankRateTransportingChecked(e.target.checked)
    }

    const handleChange = (e) => {
        if (e.target.name === 'Cancelled_Pending_Unload') {
            setCancelledCheckBoxFilter(e.target.value)
            setCurrentDefaultValueCancelledPendingUnload(e.target.value)
        } else if (e.target.name === 'Cancelled_Pending_hsd_taken') {
            setHSDDONECheckBoxFilter(e.target.value)
            setCurrentDefaultValuePendingHSDTaken(e.target.value)
        }
    }

    const checkBeforeUpload = (file) => {
        const fileType = file?.type ?? ""
        const fileSize = file?.size ?? 0
        setUploadedFile({ ...uploadedFile, file })
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" })
            return false
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            return false
        }
        return false
    }

    const resetFilterForm = (e) => {
        e.preventDefault()
        loadingFilterForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
        setStartDateFilterFormDateRange("")
        setEndDateFilterFormDateRange("")
        setCancelledCheckBoxFilter(false)
        setHSDDONECheckBoxFilter(false)
        setCurrentDefaultValueCancelledPendingUnload(null)
        setCurrentDefaultValuePendingHSDTaken(null)
        setBlankRateLoadingChecked(false)
        setBlankRateTransportingChecked(false)
        sessionStorage.removeItem('loadings_schema_filter_form_values')
    }

    const handleCancel = () => setOpenCancelLoadingPopUp(false)

    const toggleCancelLoadingPopUp = (item) => {
        setCancelLoadingId(item?.id)
        setShowCancelLoadingPopup(true)
        showModal()
    }

    const loadAllLoadings = (sortOrder, currentPage, pageSize, filterArgs = [], challanNoSearchValue = null) => {
        setSortSpinning(true);
        setCurrentPagePagination(currentPage);
        AppHelper.getAllloadings(sortOrder, currentPage, pageSize, true, filterArgs, challanNoSearchValue).then((response) => {
            setSortSpinning(false);
            setLoading(false);
            if (response?.error) {
                notification.error({
                    message: "Error",
                    description: response?.error?.message,
                    duration: 3,
                });
            } else {
                setAgencyData(response);
                setShowPagination(true);
            }
        }).catch(err => {
            setSortSpinning(false);
            notification.error({
                message: 'Error',
                description: err?.error.message,
                duration: 3
            })
            return false
        });
    };

    const filterLoadings = async (formValues) => {
        const loadingAgency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Loading_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        const transportationAgency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Transporter_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        const vehicleDetailsByNumber = vehicleLists?.data?.find((obj) => obj?.Vehicle_Number === formValues?.Vehicle_Number);
        formValues.Loading_Agency = loadingAgency?.id ?? ""
        formValues.Transporter_Agency = transportationAgency?.id ?? ""
        formValues.Vehicle_Number = vehicleDetailsByNumber?.id ?? ""
        formValues.Date_of_Loading = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        formValues.Cancelled_Pending_Unload = ((!sessionLoadingFilterValues?.Cancelled_Pending_Unload || sessionLoadingFilterValues?.Cancelled_Pending_Unload !== cancelledCheckBoxFilter) ? cancelledCheckBoxFilter : sessionLoadingFilterValues?.Cancelled_Pending_Unload)
        formValues.Cancelled_Pending_hsd_taken = ((!sessionLoadingFilterValues?.Cancelled_Pending_hsd_taken || sessionLoadingFilterValues?.Cancelled_Pending_hsd_taken !== isHSDDONECheckBoxFilter) ? isHSDDONECheckBoxFilter : sessionLoadingFilterValues?.Cancelled_Pending_hsd_taken)
        formValues.HSD_DONE = isHSDDONECheckBoxFilter

        if (blankRateLoadingChecked)
            formValues.Blank_Rate_Loading_Checkbox = blankRateLoadingChecked

        if (blankRateTransportingChecked)
            formValues.Blank_Rate_Transporting_Checkbox = blankRateTransportingChecked

        if (sessionLoadingFilterValues && sessionLoadingFilterValues?.Challan_Number)
            formValues.Challan_Number = sessionLoadingFilterValues?.Challan_Number

        loadAllLoadings(sessionSortOrderValue ?? sortUpdatedAt, currentPage, pageSize, formValues, sessionChallanNumberSearchValue);
        setOpenFilterSidebar(false)
        sessionStorage.setItem('loadings_schema_filter_form_values', AppHelper.encryptText(formValues))
    }

    const showDrawer = () => setOpenFilterSidebar(true)

    const onClose = () => setOpenFilterSidebar(false)

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue(null)
            setRangePickerEndDateValue(null)
            setStartDateFilterFormDateRange(null)
            setEndDateFilterFormDateRange(null)
        }
    }

    const showModalPopUp = () => {
        setIsModalOpen(true)
        setHideDeleteIcon(false);
    }

    const handleModelCancel = () => setIsModalOpen(false);

    const handleUploadFiles = async () => {
        if (Object.keys(uploadedFile).length === 0) {
            notification.error({ message: "Error", description: "Please upload file to import" })
            return false
        }
        // Set loading to true when upload starts
        setLoading(true);
        setHideDeleteIcon(true); // Hide delete icon when upload button is clicked
        const fileType = uploadedFile?.file?.type ?? ""
        const fileSize = uploadedFile?.file?.size ?? 0
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" });
            setLoading(false); // Set loading to false when upload finishes
            return false;
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                setLoading(false);
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            setLoading(false);
            return false
        }
        // call to an api to import
        const fileImportResponse = await AppHelper.importLoadingExcel(uploadedFile)
        if (fileImportResponse?.errors && Object.keys(fileImportResponse?.errors).length > 0) {
            setExcelImportErrors(fileImportResponse?.errors);
            setIsModalOpenExcelErrors(true);
            setIsModalOpen(false);
            setLoading(false);
        } else {
            // Check if fileImportResponse is undefined or null
            if (fileImportResponse === undefined || fileImportResponse === null || fileImportResponse === "ERR_NETWORK") {
                // Show error message if fileImportResponse is undefined or null
                notification.error({
                    message: "Error",
                    description: "Failed to import data. Please try again later.",
                });
                setLoading(false);
            } else {
                // Show success message if fileImportResponse is empty
                setIsModalOpenExcelErrors(false);
                setIsModalOpen(false);
                loadAllLoadings(sessionSortOrderValue ?? sortUpdatedAt, currentPage, pageSize, sessionLoadingFilterValues, sessionChallanNumberSearchValue);
                setLoading(false);
                notification.success({
                    message: "Success",
                    description: "Loading data imported successfully!",
                    duration: 1.5,
                });
            }
        }

    };

    const handleDeleteLoading = async (loadingId) => {
        const response = await AppHelper.deleteLoadingById(loadingId);
        if (response?.error) {
            console.error('Error response:', response); // Log the response for debugging
            notification.error({
                message: 'Error: Failed to delete loading',
                description: response?.error?.message || 'Unknown error',
                duration: 3,
            });
        } else {
            setTimeout(() => {
                notification.success({
                    message: 'Success',
                    description: 'Loading has been deleted successfully.',
                    duration: 3,
                });
            }, 500)
            loadAllLoadings(sessionSortOrderValue ?? sortUpdatedAt, currentPagePagination, pageSize, sessionLoadingFilterValues, sessionChallanNumberSearchValue);
        }
    };

    const handleRevokeLoading = async (loadingId) => {
        const response = await AppHelper.invokeCancelledLoading(loadingId);
        if (response?.error) {
            console.error('Error response:', response); // Log the response for debugging
            notification.error({
                message: 'Error: Failed to revoke cancelled challan',
                description: response?.error?.message || 'Unknown error',
                duration: 3,
            });
        } else {
            setTimeout(() => {
                notification.success({
                    message: 'Success',
                    description: 'Cancelled Challan has been revoked successfully.',
                    duration: 3,
                });
            }, 500)
            loadAllLoadings(sessionSortOrderValue ?? sortUpdatedAt, currentPagePagination, pageSize, sessionLoadingFilterValues, sessionChallanNumberSearchValue);
        }
    };

    const handleSearch = (value) => {
        setSearchChallanNumberValue(value)
        sessionStorage.setItem('loadings_schema_challan_number_search_value', AppHelper.encryptText(value))
        loadAllLoadings(sessionSortOrderValue ?? sortUpdatedAt, currentPagePagination, pageSize, sessionLoadingFilterValues, value)
    };

    const handleSortChange = (value) => {
        // Update the sorting state
        setCurrentPagePagination(1);
        // store selected sort value in session
        sessionStorage.setItem('loadings_schema_current_sort_order_value', AppHelper.encryptText(value))
        loadAllLoadings(value, 1, pageSize, sessionLoadingFilterValues, sessionChallanNumberSearchValue);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Function to show a notification when attempting to close modal during uploading
    const showCannotCloseNotification = () => {
        notification.warning({
            message: 'Uploading in Progress',
            description: 'Please wait for the file upload to complete before closing the modal.',
            duration: 3,
        });
    };

    const showGeneratedEntriesModel = (e, itemId) => {
        setGeneratedEntriesIsModalOpen(true);
        setModelItem(itemId)
    }

    const onCancelGeneratedEntriesModel = () => {
        setGeneratedEntriesIsModalOpen(false);
    }

    const exportLoadings = async () => {
        const exportResponse = await AppHelper.getAllloadings(sessionSortOrderValue ?? sortUpdatedAt, currentPage, exportPageSize, true, sessionLoadingFilterValues, sessionChallanNumberSearchValue)
        if (exportResponse?.error) {
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Vehicle List", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: 'S.NO.', key: "sl_no", width: 8 },
                { header: 'Challan Number', key: "Challan_Number", width: 20 },
                { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                { header: 'Vehicle Number', key: "Vehicle_Number", width: 20 },
                { header: 'Owner', key: "OwnerValue", width: 25 },
                { header: 'Loading Agency', key: "Loading_Agency", width: 20 },
                { header: 'Thermal Plant book no', key: "Thermal_Plant_Book_No", width: 20 },
                { header: 'Transporting Agency', key: "Transport_Agency", width: 20 },
                { header: 'Kanta Slip No. at Loading', key: "Kanta_Slip_No", width: 25 },
                { header: 'Net Weight at Loading (Kg)', key: "Net_Weight", width: 15 },
                { header: 'Tare Weight at Loading (Kg)', key: "Tare_Weight", width: 15 },
                { header: 'Gross Weight at Loading (Kg)', key: "Gross_Weight", width: 15 },
                { header: 'D/O No.', key: "DO_No", width: 15 },
                { header: 'Loading By Employee', key: "Loading_by_Employee", width: 15 },
                { header: 'Remarks', key: "Remarks", width: 20 },
                { header: 'Loading Agency Rate Type', key: "Loading_Rate_Type", width: 20 },
                { header: 'Loading Agency Rate Value', key: "Loading_Rate_Value", width: 25 },
                { header: 'Transportation Agency Rate Type', key: "Transportation_Rate_Type", width: 20 },
                { header: 'Transportation Agency Rate Value', key: "Transportation_Rate_Value", width: 20 }
            ]

            exportResponse?.data?.attributes?.results?.forEach((billData, key) => {
                billData.sl_no = key + 1
                billData.Date_of_Loading = dayjs(billData?.Date_of_Loading).format(dateFormat)
                billData.OwnerValue = billData?.Vehicle_Number?.Owner ?? ""
                billData.Vehicle_Number = billData?.Vehicle_Number?.Vehicle_Number ?? ""
                billData.Loading_Agency = billData?.Loading_Agency?.Company_name ?? ""
                billData.Transport_Agency = billData?.Transporter_Agency?.Company_name ?? ""
                billData.Thermal_Plant_Book_No = billData?.Thermal_Plant_Book_no ?? ""
                billData.Kanta_Slip_No = billData?.Kanta_Slip_No ?? ""
                billData.Loading_Rate_Type = billData?.Loading_Rate_Type === 'per_unit' ? 'Per (Unit)' : "Per (MT)"
                billData.Transportation_Rate_Type = billData?.Transportation_Rate_Type === 'per_unit' ? 'Per (Unit)' : "Per (MT)"
                workSheetPendingBills.addRow(billData)
            })

            // preapare sheet for global counts

            const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Global Counts")
            // prepare sheet columns / headers
            workSheetPendingBillGlobalCounts.columns = [
                { header: 'TP', key: "first_value", width: 25 },
                { header: exportResponse?.data?.attributes?.results?.[0]?.Project?.Thermal_Plant?.Name, key: "second_value", width: 25 },
            ]

            // prepare sheet rows to display
            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Project", second_value: exportResponse?.data?.attributes?.results?.[0]?.Project?.Name_of_project ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Total Count", second_value: exportResponse?.meta?.totalLoading ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Cancel Count", second_value: exportResponse?.meta?.cancelledLoading ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Pending to Unload", second_value: exportResponse?.meta?.unloadPending ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Pending to HSD", second_value: exportResponse?.meta?.hsdPending ?? 0 }
            )

            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `loading_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Loading data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
        }
    }

    useEffect(() => {
        if (sortUpdatedAt && sortUpdatedAt !== "") {
            sessionStorage.setItem('loadings_schema_current_sort_order_value', AppHelper.encryptText(sortUpdatedAt))
        }
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setLoadingAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Loading', sessionGlobalValues));
            setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', sessionGlobalValues));

            // Fetch vehicle and agency data
            AppHelper.getVehiclesWithoutPagination().then((responseVehicle) => {
                setVehicleLists(responseVehicle);
                setVehicleDropDown(AppHelper.getVehicleNumberDropDownWithoutPagination(responseVehicle?.data));

                // load loading filter form values if stored in session
                if (sessionLoadingFilterValues && Object.keys(sessionLoadingFilterValues).length > 0) {
                    // if filter values available in session then display and keep selected the values in filter form
                    // remove `Date` key from session object to prevent overlapping
                    let updatedSessionLoadingFilterFormValues = Object.keys(sessionLoadingFilterValues).filter((key) => key !== 'Date').reduce((object, key) => {
                        object[key] = sessionLoadingFilterValues[key];
                        return object
                    }, {})
                    // let updatedSessionLoadingFilterFormValues = { ...sessionLoadingFilterValues }
                    const loadingAgency = response.find((item) => item?.id === sessionLoadingFilterValues?.Loading_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
                    const transportationAgency = response.find((item) => item?.id === sessionLoadingFilterValues?.Transporter_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
                    const vehicleDetailsByNumber = responseVehicle?.data?.find((obj) => obj?.id === sessionLoadingFilterValues?.Vehicle_Number);
                    updatedSessionLoadingFilterFormValues.Loading_Agency = loadingAgency?.attributes?.Company_name ?? null
                    updatedSessionLoadingFilterFormValues.Transporter_Agency = transportationAgency?.attributes?.Company_name ?? null
                    updatedSessionLoadingFilterFormValues.Vehicle_Number = vehicleDetailsByNumber?.Vehicle_Number ?? null

                    // set Date range values in filter form
                    const getDateOfRangeFilterForm = updatedSessionLoadingFilterFormValues?.Date_of_Loading
                    const splitGetDateofRangeFilterForm = getDateOfRangeFilterForm.split("~")
                    const startDate = splitGetDateofRangeFilterForm[0]
                    const endDate = splitGetDateofRangeFilterForm[1] ?? ''
                    if (startDate && startDate !== "" && endDate && endDate !== "") {
                        setStartDateFilterFormDateRange(dayjs(dayjs(startDate, dateFormatDayjsConversion)))
                        setRangePickerStartDateValue(dayjs(startDate).format("YYYY-MM-DD"))
                        setEndDateFilterFormDateRange(dayjs(dayjs(endDate, dateFormatDayjsConversion)))
                        setRangePickerEndDateValue(dayjs(endDate).format("YYYY-MM-DD"))
                    }
                    setCurrentDefaultValueCancelledPendingUnload(sessionLoadingFilterValues?.Cancelled_Pending_Unload)
                    setCurrentDefaultValuePendingHSDTaken(sessionLoadingFilterValues?.Cancelled_Pending_hsd_taken)
                    setCancelledCheckBoxFilter(sessionLoadingFilterValues?.Cancelled_Pending_Unload)
                    setSearchChallanNumberValue(sessionLoadingFilterValues?.Challan_Number)
                    setHSDDONECheckBoxFilter(sessionLoadingFilterValues?.HSD_DONE)
                    setBlankRateLoadingChecked(sessionLoadingFilterValues?.Blank_Rate_Loading_Checkbox)
                    setBlankRateTransportingChecked(sessionLoadingFilterValues?.Blank_Rate_Transporting_Checkbox)
                    loadingFilterForm.setFieldsValue(updatedSessionLoadingFilterFormValues)
                }
                loadAllLoadings(AppHelper.decryptText(sessionStorage.getItem('loadings_schema_current_sort_order_value')), currentPage, pageSize, sessionLoadingFilterValues, sessionChallanNumberSearchValue);
            }).catch(err => {
                console.log(err)
            });
        }).catch(err => {
            console.log(err);
        });
        document.title = "Loadings List";
    }, []);

    return (
        <>
            <>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes['header-fixed-loading']}
                >
                    <Grid item lg={4} md={2} xs={12}>
                        <Typography
                            variant="h1"
                            fontSize="1.5rem"
                            fontWeight="bold"
                            className="title-un-loading-text"
                        >
                            Loading
                        </Typography>
                    </Grid>
                    <Grid item lg={7} md={8} xs={6} container spacing={0} className={classes["gridmargintop10"]}>
                        <Grid item lg={3} md={3} xs={6} className={classes["form-select-div-search"]}>
                            <Search
                                placeholder="Search By Challan No."
                                className={classes["search-new-entry"]}
                                value={((sessionChallanNumberSearchValue && sessionChallanNumberSearchValue !== "") ? sessionChallanNumberSearchValue : searchChallanNumberValue)}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </Grid>
                        <Grid item lg={3} md={3} xs={6} className={classes["form-select-div-select"]}>
                            <Form>
                                <Form.Item label="Sort By">
                                    <Select className={classes["select-new-entry"]} defaultValue={((sessionSortOrderValue && sessionSortOrderValue !== "") ? sessionSortOrderValue : sortUpdatedAt ?? 'Challan_Number:desc')} onChange={handleSortChange}>
                                        <Select.Option value="updatedAt:desc" >Updated Date</Select.Option>
                                        <Select.Option value="Date_of_Loading:asc">Date Ascending</Select.Option>
                                        <Select.Option value="Date_of_Loading:desc">Date Descending</Select.Option>
                                        <Select.Option value="Challan_Number:asc">Challan Number Ascending</Select.Option>
                                        <Select.Option value="Challan_Number:desc">Challan Number Descending</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Grid>
                        <Grid item lg={2} md={2} xs={3}>
                            <UIButton
                                type="primary"
                                onClick={showModalPopUp}
                                variant="outlined"
                                startIcon={<ImportOutlined />}
                                className={classes["import-new-entry"]}
                            >
                                Import
                            </UIButton>
                        </Grid>
                        <Grid item lg={2} md={2} xs={3}>
                            <UIButton
                                onClick={showDrawer}
                                variant="outlined"
                                startIcon={<FilterOutlined />}
                                className={classes["filter-new-entry"]}
                            >
                                Filters
                            </UIButton>
                            <Drawer title="Filters" onClose={onClose} open={openFilterSidebar}>
                                <Form layout="vertical" form={loadingFilterForm} onFinish={filterLoadings}>
                                    <Form.Item label="Transporting Agency" name="Transporter_Agency">
                                        <Select
                                            options={transportationAgencyDropDown}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            placeholder="Select"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Loading Date" name="Date">
                                        <RangePicker style={{ width: "100%" }} onChange={handleRangePicker} format={dateFormat} defaultValue={[startDateFilterFormDateRange, endDateFilterFormDateRange]} />
                                    </Form.Item>
                                    <Form.Item label="Vehicle Number" name="Vehicle_Number">
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            style={{ width: "100%" }}
                                            options={vehicleDropDown}
                                            placeholder="Select"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Loading Agency" name="Loading_Agency">
                                        <Select
                                            options={loadingAgencyDropDown}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            placeholder="Select"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Thermal Plant Book No." name="Thermal_Plant_Book_no">
                                        <Input
                                            placeholder="Enter Thermal Plant Book No."
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Kanta Slip No. at Loading" name="Kanta_Slip_No">
                                        <Input
                                            placeholder="Enter Kanta Slip No. at Loading"
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <Radio.Group name="Cancelled_Pending_Unload" onChange={handleChange} value={currentDefaultValueCancelledPendingUnload} style={{ width: '100%' }}>
                                        <Row gutter={[24]} wrap={true}>
                                            <Col span={24}>
                                                <Form.Item
                                                    messageVariables=""
                                                    wrapperCol={{ span: 30 }}
                                                    labelCol={{ span: 30 }}
                                                    className={classes['ant-form-item-radio']}
                                                >
                                                    <Row gutter={[24, 24]} wrap={true}>
                                                        <Col span={12}>
                                                            <Radio value={1} name="Cancelled_Pending_Unload_One">Cancelled</Radio>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Radio value={3} name="Cancelled_Pending_Unload_Three">Not Cancelled</Radio>
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item
                                                    messageVariables=""
                                                    wrapperCol={{ span: 30 }}
                                                    labelCol={{ span: 30 }}
                                                    className={classes['ant-form-item-radio']}
                                                >
                                                    <Row gutter={[24, 24]} wrap={true}>
                                                        <Col span={24}>
                                                            <Radio value={2} name="Cancelled_Pending_Unload_Two">Pending Unload</Radio>
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Radio.Group>
                                    <Row gutter={[24, 24]} wrap={true}>
                                        <Col span={24}>
                                            <Form.Item
                                                messageVariables=""
                                                wrapperCol={{ span: 30 }}
                                                labelCol={{ span: 30 }}
                                                className={classes['ant-form-item']}
                                            >
                                                <Radio.Group name="Cancelled_Pending_hsd_taken" onChange={handleChange} value={currentDefaultValuePendingHSDTaken} style={{ width: '100%' }}>
                                                    <Row gutter={[24, 24]} wrap={true}  >
                                                        <Col span={12}>
                                                            <Radio name="isHSD_Done" value={3}>Pending HSD</Radio>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Radio name="isHSD_Taken" value={4}>HSD Taken</Radio>
                                                        </Col>
                                                    </Row>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item label="Employee at Loading Point" name="Loading_by_Employee">
                                        <Input
                                            placeholder="Enter Employee at Loading Point"
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <Row gutter={[24, 24]} wrap={true}>
                                        <Col span={24}>
                                            <Form.Item
                                                messageVariables=""
                                                wrapperCol={{ span: 30 }}
                                                labelCol={{ span: 30 }}
                                                className={classes['ant-form-item-radio']}
                                            >
                                                <Row gutter={[24, 24]} wrap={true}>
                                                    <Col span={24}>
                                                        <Checkbox value={1} onChange={handleChangeBlankRateLoadingCheckbox} checked={blankRateLoadingChecked}>Blank Rate Loading</Checkbox>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]} wrap={true}>
                                        <Col span={24}>
                                            <Form.Item
                                                messageVariables=""
                                                wrapperCol={{ span: 30 }}
                                                labelCol={{ span: 30 }}
                                                className={classes['ant-form-item-radio']}
                                            >
                                                <Row gutter={[24, 24]} wrap={true}>
                                                    <Col span={24}>
                                                        <Checkbox value={1} onChange={handleChangeBlankRateTransportationCheckbox} checked={blankRateTransportingChecked}>Blank Rate Transportation</Checkbox>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item label="D/O No." name="DO_No">
                                        <Input
                                            placeholder="Enter D/O NO."
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <div className={classes["search-button-area"]}>
                                        <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                            Reset
                                        </UIButton>
                                        <UIButton
                                            size="medium"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className={classes["add-new-filter-entry"]}
                                        >
                                            Apply
                                        </UIButton>
                                    </div>
                                </Form>
                            </Drawer>
                        </Grid>
                        <Grid item lg={2} md={2} xs={3}>
                            <UIButton
                                size="small"
                                variant="contained"
                                onClick={redirectToCreateLoading}
                                className={classes["add-new-entry"]}
                            >
                                + Add new
                            </UIButton>
                        </Grid>
                    </Grid>
                    <Grid item lg={1} md={2} xs={3} className={`${classes['right-align']} ${classes["gridmargintop5"]}`}>
                        <UIButton
                            size="small"
                            variant="contained"
                            onClick={exportLoadings}
                            startIcon={<ExportOutlined />}
                            className={classes["add-new-entry"]}
                        >
                            Export
                        </UIButton>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} container spacing={0} className={classes["headerMarginTop"]}>
                        <Grid item lg={3} md={2} xs={1}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-first"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={totalCountImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.totalLoading ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.totalLoading ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Total Count
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={2} xs={1}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-second"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={cancelImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.cancelledLoading ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.cancelledLoading ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Cancel Count
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={2} xs={1}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-third"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={pendingUnloadngImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.unloadPending ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.unloadPending ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Pending to Unload
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={2} xs={1}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-fourth"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={pendingHsdImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.hsdPending ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.hsdPending ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Pending to HSD
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
            <>
                <Grid container spacing={0} className={classes["marginTop20"]}>
                    {!agencyData?.data?.attributes?.results || sortSpinning ? (
                        <Spin size="large" tip="Please Wait ...">
                            <div className={classes["content"]} />
                        </Spin>
                    ) : agencyData?.data?.attributes?.results?.length === 0 ? (
                        // Show message when data is not available
                        <Typography variant="body1">
                            No loading record is available
                        </Typography>
                    ) : (agencyData?.data?.attributes?.results?.map((item) => (
                        <Grid key={item.id} item lg={4} md={6} xs={12}>
                            <Card bordered={true} className={classes["card-box"]}>
                                <div className={classes["AgencyDetails"]}>
                                    <Typography component="div" gutterBottom fontWeight="bold">
                                        Challan No:&nbsp;
                                        {
                                            item?.Cancelled === true ?
                                                <span className={classes["cancelled-loading-challan-no"]}>
                                                    {item?.Challan_Number}
                                                </span>
                                                :
                                                item?.Challan_Number
                                        }
                                    </Typography>
                                    <Typography gutterBottom variant="body2">
                                        Vehicle No :
                                        <span className={classes["AgencyDetailsValue"]}>
                                            {
                                                item?.Vehicle_Number?.Vehicle_Number ?? "-"
                                            }
                                        </span>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Date of Loading :
                                        <span className={classes["AgencyDetailsValue"]}>
                                            {formatDate(item?.Date_of_Loading ?? "-")}
                                        </span>
                                    </Typography>
                                    <Typography gutterBottom variant="body2">
                                        Loading Agency :
                                        <span className={classes["AgencyDetailsValue"]}>
                                            {item?.Loading_Agency?.Company_name.length > 20 ? <span title={item?.Loading_Agency?.Company_name}>{item?.Loading_Agency?.Company_name.substring(0, 20)}....</span> : item?.Loading_Agency?.Company_name ?? "-"}
                                        </span>
                                    </Typography>
                                    <Typography gutterBottom variant="body2">
                                        Transporting Agency :
                                        <span className={classes["AgencyDetailsValue"]}>
                                            {item?.Transporter_Agency?.Company_name.length > 20 ? <span title={item?.Transporter_Agency?.Company_name}>{item?.Transporter_Agency?.Company_name.substring(0, 20)}....</span> : item?.Transporter_Agency?.Company_name ?? "-"}
                                        </span>
                                    </Typography>
                                    {item?.Cancelled && <Typography variant="body2" gutterBottom>
                                        Status :
                                        <span className={classes["AgencyDetailsValue"]}>
                                            Cancelled
                                        </span>
                                    </Typography>}
                                </div>
                                <div className={classes["card-button"]}>
                                    <ul className={classes['action-items']}>
                                        <li>
                                            <Link to={`/loadings/view/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}>
                                                <EyeOutlined
                                                    className={classes["card-icons"]}
                                                    style={{ color: "#000", float: "right" }}
                                                />
                                            </Link>
                                        </li>
                                        {
                                            (
                                                (item?.is_client_billed && item?.Client_Bill?.id && item?.Client_Bill?.id !== "")
                                                || (item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                                || (item?.is_transporting_billed && item?.Transporting_Bill?.id && item?.Transporting_Bill?.id !== "")
                                                || (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "")
                                            ) ? (
                                                <li>
                                                    {(!item?.Cancelled) ? (
                                                        <Link
                                                            title={item?.Unloading?.id ? "Edit Unload Details" : "Create Unload Details"}
                                                        >
                                                            <img

                                                                src={item?.Unloading?.id ? unloadImgBlack : unloadImgWhite}
                                                                alt=""
                                                                className={`${item?.Unloading?.id ? `${classes["card-icons"]} ${classes["card-icons-images"]}` : `${classes["card-icons"]} ${classes["card-icons-images"]} ${classes["create-unload-card-img"]}`} ${item?.Cancelled ? classes['disabled-icon'] : ''}`}
                                                                // style={{ cursor: item?.Cancelled ? 'not-allowed' : 'pointer' }}
                                                                onClick={(e) => showGeneratedEntriesModel(e, item)}
                                                            />
                                                        </Link>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={unloadImgBlack}
                                                                alt=""
                                                                className={`${classes["card-icons"]} ${classes["card-icons-images"]} ${classes["unload-disabled"]}`}
                                                                // style={{ cursor: item?.Cancelled ? 'not-allowed' : 'pointer' }}
                                                                onClick={(e) => showGeneratedEntriesModel(e, item)}
                                                            />
                                                        </>
                                                    )}
                                                </li>
                                            ) : (
                                                <li>
                                                    {(!item?.Cancelled) ? (
                                                        <Link
                                                            to={item?.Unloading ? `/unloadings/edit/${item?.Unloading?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}&redirectbackto=true` : `/unloadings/create/${item?.id}`}
                                                            title={item?.Unloading?.id ? "Edit Unload Details" : "Create Unload Details"}
                                                        >
                                                            <img
                                                                src={item?.Unloading?.id ? unloadImgBlack : unloadImgWhite}
                                                                alt=""
                                                                className={`${item?.Unloading?.id ? `${classes["card-icons"]} ${classes["card-icons-images"]}` : `${classes["card-icons"]} ${classes["card-icons-images"]} ${classes["create-unload-card-img"]}`} ${item?.Cancelled ? classes['disabled-icon'] : ''}`}
                                                                // style={{ cursor: item?.Cancelled ? 'not-allowed' : 'pointer' }}
                                                                onClick={item?.Cancelled ? (e) => e.preventDefault() : null}
                                                            />
                                                        </Link>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={unloadImgBlack}
                                                                alt=""
                                                                className={`${classes["card-icons"]} ${classes["card-icons-images"]} ${classes["unload-disabled"]}`}
                                                                // style={{ cursor: item?.Cancelled ? 'not-allowed' : 'pointer' }}
                                                                onClick={item?.Cancelled ? (e) => e.preventDefault() : null}
                                                            />
                                                        </>
                                                    )}
                                                </li>
                                            )}
                                        {
                                            (
                                                (item?.is_client_billed && item?.Client_Bill?.id && item?.Client_Bill?.id !== "")
                                                || (item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                                || (item?.is_transporting_billed && item?.Transporting_Bill?.id && item?.Transporting_Bill?.id !== "")
                                                || (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "")
                                            )
                                                ?
                                                (
                                                    <li>
                                                        {
                                                            !item?.Cancelled
                                                                ?
                                                                (!item?.Unloading?.id || item?.Unloading?.id === null)
                                                                    ?
                                                                    <li>
                                                                        <Link
                                                                            title="Cancel"
                                                                        >
                                                                            <img src={cancelledIconRed} alt="" className={classes["card-icons"]} onClick={(e) => showGeneratedEntriesModel(e, item)} />
                                                                        </Link>
                                                                    </li>
                                                                    :
                                                                    <li>
                                                                        <img src={cancelledIconWhite} alt="" className={`${classes["card-icons"]} ${classes['disabled-icon']}`} onClick={(e) => showGeneratedEntriesModel(e, item)} />
                                                                    </li>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            item?.Cancelled
                                                                ?
                                                                <li>
                                                                    <img
                                                                        src={revokeLoadingIcon}
                                                                        alt=""
                                                                        width={30}
                                                                        className={`${classes["card-icons"]}`}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => showGeneratedEntriesModel(e, item)}
                                                                    />
                                                                </li>
                                                                :
                                                                ""
                                                        }
                                                    </li>
                                                ) : (
                                                    <li>
                                                        {
                                                            !item?.Cancelled
                                                                ?
                                                                (!item?.Unloading || item?.Unloading?.id === null)
                                                                    ?
                                                                    <li>
                                                                        <Link
                                                                            title="Cancel"
                                                                            onClick={() => toggleCancelLoadingPopUp(item)}
                                                                        >
                                                                            <img src={cancelledIconRed} alt="" className={classes["card-icons"]} />
                                                                        </Link>
                                                                    </li>
                                                                    :
                                                                    <li>
                                                                        <img src={cancelledIconWhite} alt="" className={`${classes["card-icons"]} ${classes['disabled-icon']}`} />
                                                                    </li>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            item?.Cancelled
                                                                ?
                                                                <li>
                                                                    <Popconfirm
                                                                        title="Revoke Cancelled Challan"
                                                                        description="Are you sure, you want to revoke this loading record?"
                                                                        onConfirm={() => handleRevokeLoading(item?.id)}
                                                                    >
                                                                        <img
                                                                            src={revokeLoadingIcon}
                                                                            alt=""
                                                                            width={30}
                                                                            className={`${classes["card-icons"]}`}
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={item?.Cancelled ? (e) => e.preventDefault() : null}
                                                                        />
                                                                    </Popconfirm>
                                                                </li>
                                                                :
                                                                ""
                                                        }
                                                    </li>
                                                )}
                                    </ul>
                                    <ul className={classes['action-items']}>
                                        {
                                            <li>
                                                <Link to={`/loadings/edit/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}>
                                                    <EditOutlined
                                                        className={classes["card-icons"]}
                                                        style={{ color: "#000" }}
                                                    />
                                                </Link>
                                            </li>
                                        }
                                        {
                                            (
                                                (item?.is_client_billed && item?.Client_Bill?.id && item?.Client_Bill?.id !== "")
                                                || (item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                                || (item?.is_transporting_billed && item?.Transporting_Bill?.id && item?.Transporting_Bill?.id !== "")
                                                || (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "")
                                            )
                                                ? (
                                                    <li>
                                                        <Link
                                                            to={item?.hsd_detail ? `/hsd-details/edit/${item?.hsd_detail?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}&redirectbackto=true` : `/hsd-details/create/loading/${item?.id}`}
                                                            title={item?.hsd_detail ? "Edit HSD Details" : "Create HSD Details"}
                                                        >
                                                            <img
                                                                src={item?.hsd_detail ? petrolPumpIconBlack : petrolPumpIconWhite}
                                                                alt=""
                                                                className={`${item?.hsd_detail ? `${classes["card-icons"]} ${classes["card-icons-images"]}` : `${classes["card-icons"]} ${classes["card-icons-images"]} ${classes["create-hsd-card-img"]}`}`}
                                                            />
                                                        </Link>
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <Link
                                                            to={item?.hsd_detail ? `/hsd-details/edit/${item?.hsd_detail?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}&redirectbackto=true` : `/hsd-details/create/loading/${item?.id}`}
                                                            title={item?.hsd_detail ? "Edit HSD Details" : "Create HSD Details"}
                                                        >
                                                            <img
                                                                src={item?.hsd_detail ? petrolPumpIconBlack : petrolPumpIconWhite}
                                                                alt=""
                                                                className={`${item?.hsd_detail ? `${classes["card-icons"]} ${classes["card-icons-images"]}` : `${classes["card-icons"]} ${classes["card-icons-images"]} ${classes["create-hsd-card-img"]}`}`}
                                                            />
                                                        </Link>
                                                    </li>
                                                )
                                        }
                                        {
                                            (
                                                (item?.is_client_billed && item?.Client_Bill?.id && item?.Client_Bill?.id !== "")
                                                || (item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                                || (item?.is_transporting_billed && item?.Transporting_Bill?.id && item?.Transporting_Bill?.id !== "")
                                                || (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "")
                                            )
                                                ? (
                                                    <li>
                                                        <DeleteOutlined
                                                            title="Delete loading"
                                                            className={`${classes['card-icons']} ${item?.Cancelled || item?.Unloading || item?.hsd_detail ? classes['disabled-icon'] : ''}`}
                                                            style={{ color: item?.Cancelled || item?.Unloading || item?.hsd_detail ? '#fff' : '#ff0000' }}
                                                            onClick={(e) => showGeneratedEntriesModel(e, item)}
                                                        />
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <Popconfirm
                                                            title="Delete the Entry"
                                                            description="Are you sure, you want to delete this loading record?"
                                                            icon={<QuestionCircleOutlined style={{ color: '#ff0000' }} />}
                                                            onConfirm={() => handleDeleteLoading(item?.id)}
                                                        >
                                                            <DeleteOutlined
                                                                title="Delete loading"
                                                                className={`${classes['card-icons']} ${item?.Cancelled || item?.Unloading || item?.hsd_detail ? classes['disabled-icon'] : ''}`}
                                                                style={{ color: item?.Cancelled || item?.Unloading || item?.hsd_detail ? '#fff' : '#ff0000' }}
                                                            />
                                                        </Popconfirm>
                                                    </li>
                                                )}
                                    </ul>
                                    <div className={
                                        (
                                            (item?.is_client_billed && item?.Client_Bill?.id && item?.Client_Bill?.id !== "")
                                            || (item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                            || (item?.is_transporting_billed && item?.Transporting_Bill?.id && item?.Transporting_Bill?.id !== "")
                                            || (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "")
                                        ) ? `${classes["generated-bill-ribbon"]}` : ""
                                    }>
                                        <Typography variant="body2">
                                            {
                                                (
                                                    (item?.is_client_billed && item?.Client_Bill?.id && item?.Client_Bill?.id !== "")
                                                    || (item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                                    || (item?.is_transporting_billed && item?.Transporting_Bill?.id && item?.Transporting_Bill?.id !== "")
                                                    || (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "")
                                                ) ? "Generated" : ""}
                                        </Typography>
                                    </div>

                                    <>
                                        <Modal open={isGeneratedEntriesModalOpen} onCancel={onCancelGeneratedEntriesModel} centered footer={null} width={700}>
                                            <Grid className={classes["alert-bottom-border"]}>
                                                <Typography variant="h5" color="initial" className={classes["alert-text"]}>Alert !</Typography>
                                            </Grid>
                                            <Grid>
                                                <Typography variant="body1" color="#555555" className={classes["alert-description"]}>Editing this entry is only allowed after it's been removed from the generated bills</Typography>
                                            </Grid>
                                            <Grid
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                                alignContent="center"
                                                className={classes["generated-entries-modal-table"]}
                                                container
                                            >

                                                <Grid className={classes["generated-entries-model-heading"]}>
                                                    <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="#908f8f" paddingLeft="15px">Bill Type</Typography></Grid>
                                                    <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="#908f8f">Agency Name</Typography></Grid>
                                                    <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="#908f8f">Bill No.</Typography></Grid>
                                                </Grid>
                                                {
                                                    (modelItem?.is_transporting_billed && modelItem?.Transporting_Bill?.id && modelItem?.Transporting_Bill?.id !== "")
                                                        ?
                                                        <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                            <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Transporting Bill</Typography></Grid>
                                                            <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">{modelItem?.Transporter_Agency?.Company_name}</Typography></Grid>
                                                            <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Transporting_Bill?.bill_number}</Typography></Grid>
                                                        </Grid>
                                                        :
                                                        ""
                                                }
                                                {
                                                    modelItem?.is_loading_billed && modelItem?.Loading_Bill?.id && modelItem?.Loading_Bill?.id !== ""
                                                        ?
                                                        <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                            <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Loading Bill</Typography></Grid>
                                                            <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">{modelItem?.Loading_Agency?.Company_name}</Typography></Grid>
                                                            <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Loading_Bill?.bill_number}</Typography></Grid>
                                                        </Grid>
                                                        :
                                                        ""
                                                }
                                                {
                                                    modelItem?.is_unloading_billed && modelItem?.Unloading_Bill?.id && modelItem?.Unloading_Bill?.id !== ""
                                                        ?
                                                        <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                            <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Unloading Bill</Typography></Grid>
                                                            <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">{modelItem?.Unloading?.Unloading_Agency?.Company_name}</Typography></Grid>
                                                            <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Unloading_Bill?.bill_number}</Typography></Grid>
                                                        </Grid>
                                                        :
                                                        ""
                                                }

                                                {
                                                    modelItem?.is_client_billed && modelItem?.Client_Bill?.id && modelItem?.Client_Bill?.id !== ""
                                                        ?
                                                        <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                            <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Client Bill</Typography></Grid>
                                                            <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">-</Typography></Grid>
                                                            <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Client_Bill?.bill_no}</Typography></Grid>
                                                        </Grid>
                                                        :
                                                        ""
                                                }
                                            </Grid>
                                        </Modal>
                                    </>
                                </div>
                            </Card>
                        </Grid>
                    ))
                    )}
                </Grid>
            </>
            <>
                {
                    showPagination === true && agencyData?.data?.attributes?.results?.length > 0 ? (
                        <div className={classes["pagination-box"]}>
                            <Pagination
                                current={currentPagePagination}
                                showSizeChanger={false}
                                defaultCurrent={currentPage}
                                defaultPageSize={currentPageSizePagination}
                                showTotal={(total) => `Total ${total} items`}
                                responsive
                                pageSize={currentPageSizePagination}
                                onChange={changePageNumber}
                                total={agencyData?.data?.attributes?.pagination?.total}
                            />
                        </div>
                    ) : (
                        ""
                    )
                }
            </>
            {
                showCancelLoadingPopup &&
                <>
                    <Modal
                        title="Cancel Loading"
                        open={openCancelLoadingPopUp}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}
                    >
                        <p>{modalText}</p>

                        <TextArea
                            name="cancelLoadingReason"
                            required
                            rows={4}
                            placeholder="Please enter the reason for loading cancellation"
                            maxLength={4000}
                        />
                    </Modal>
                </>
            }
            <Modal
                centered
                open={isModalOpen}
                onCancel={loading ? showCannotCloseNotification : handleModelCancel}
                footer={(_) => (
                    <>
                        <UIButton
                            onClick={loading ? showCannotCloseNotification : handleModelCancel}
                            variant="outlined"
                            color="error"
                            className={classes['model-buttons']}
                        >
                            Cancel
                        </UIButton>
                        <UIButton
                            onClick={handleUploadFiles}
                            variant="contained"
                            className={classes["add-new-filter-entry"]}
                            disabled={loading} // Disable the Upload button while loading
                        >
                            {loading ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} />} /> : 'Upload'} {/* Show spinner while loading */}
                        </UIButton>
                    </>
                )}
            >

                <Upload {...props}>
                    <UIButton
                        startIcon={<UploadOutlined />}
                        className={classes['upload-file-entry']}
                        variant="outlined"
                    >
                        Browse File
                    </UIButton>
                </Upload>
                <h4>Please choose file .xls and .xlsx only. <a href={importLoadingSampleFile} download={true} className={classes["click-here-link"]}>Click here</a> to download the sample file.</h4>
                <style>{`
         & .ant-upload-list-item-actions .ant-btn {
          display: ${hideDeleteIcon ? 'none' : 'inline-block'};
        }
      `}</style>
            </Modal>
            {/**
       * Modal popup for excel import errors display
       */}
            {
                excelImportErrors && Object.keys(excelImportErrors).length > 0
                    ?
                    <Modal
                        centered
                        open={isModalOpenExcelErrors}
                        onCancel={handleModelExcelErrorsCancel}
                        width={'60%'}
                        className={classes['error-import-modal']}
                        footer=""
                    >
                        <>
                            <h3 className={classes["error-import-modal-heading"]}>Please find errors in Loading import excel data as below:</h3>
                            <div className={classes['error-import-excel-display-data']}>
                                <table border={0} cellPadding={0} cellSpacing={0} width={'100%'}>
                                    <thead>
                                        <tr>
                                            <th width={'8%'} align="center">Row No</th>
                                            <th width={'92%'}>Errors</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            excelImportErrors.map((value, key) => {
                                                if (value?.length > 0) {
                                                    return <tr key={key}>
                                                        <td align="center"><strong>{parseInt(key) + 2}</strong></td>
                                                        <td>
                                                            <table border={0} cellPadding={0} cellSpacing={0} key={key} width={'100%'}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <ul>
                                                                                {
                                                                                    value?.map((item, key) => {

                                                                                        return <li key={key}>
                                                                                            <p>{item}</p>
                                                                                        </li>
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
        </>
    );
};