import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Grid, Button as UIButton, styled } from "@mui/material";
import { ArrowLeftOutlined } from "@ant-design/icons";
import classes from "./ViewUnloadData.module.scss";
import { Form, Input, Upload, Modal } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const CustomUpload = styled(Upload)(() => ({
    "& .ant-upload-list-item button": {
        display: "none !important"
    }
}));

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const ViewUnloadData = () => {
    const navigate = useNavigate();
    const params = useParams();
    const unLoadId = params?.id ?? "";
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const [unLoadDetails, setUnLoadDetails] = useState("");
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const maxNoOfUploadsAllowed = constantsArr.MAX_UPLOAD_IMAGES
    const [viewPdf, setViewPdf] = useState(null);

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const getUnLoadingDetails = async () => {
        const response = await AppHelper.getUnloadingById(unLoadId);
        let uploadedPictures = response?.data?.attributes?.Uploaded_Pictures?.data?.attributes?.Pictures ?? "";

        if (uploadedPictures) {
            uploadedPictures = JSON.parse(uploadedPictures)
        }

        const newFileList = uploadedPictures.length > 0 ? uploadedPictures.map((image) => ({
            uid: image?.id,
            name: image?.name,
            status: "done",
            type: image?.mime,
            url: image?.url,
        })) : [];
        setUnLoadDetails(response.data);
        setFileList(newFileList);
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    const backToUnloadingsList = (e) => {
        e.preventDefault();
        navigate(`/unloadings?currentPage=${pageNo}&pageSize=${pageSize}`);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        document.title = "View Unloading Details"
        getUnLoadingDetails();
    }, []);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["view-loading-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                    >
                        View Unloading Details (Challan No: {unLoadDetails?.attributes?.Challan_Number})
                    </Typography>
                </Grid>
                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <UIButton
                        variant="contained"
                        size="small"
                        onClick={backToUnloadingsList}
                        className={classes["add-new-entry"]}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Unloadings
                    </UIButton>
                </Grid>
            </Grid>

            <div className={classes["agency-details-margin"]}>
                <Form layout="vertical">
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Challan No.">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Challan_Number}
                                    name="Challan_Number"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Vehicle Number">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Vehicle_Number?.data?.attributes?.Vehicle_Number}
                                    name="Vehicle_Number"
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Thermal Plant Book No.">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Thermal_Plant_Book_no ?? ""}
                                    name="Thermal_Plant_Book_no"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Unloading Kanta Slip No.">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Kanta_Slip_No ?? ""}
                                    name="Kanta_Slip_No"
                                />
                            </Form.Item>
                            <Form.Item
                                messageVariables=""
                                label="Tare Weight at Unloading (Kg)"
                            >
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Tare_Weight ?? "0"}
                                    name="Tare_Weight"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Gross Weight Through RC">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Vehicle_Number?.data?.attributes?.Gross_Weight ?? 0}
                                    name="Kanta_Slip_No"
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Unloading Agency">
                                <Input
                                    readOnly
                                    value={
                                        unLoadDetails?.attributes?.Unloading_Agency?.data?.attributes
                                            ?.Company_name
                                    }
                                    name="Unloading_Agency"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Employee at Unloading Point">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Unloading_by_Employee ?? ""}
                                    name="Unloading_by_Employee"

                                />
                            </Form.Item>
                            {/* <Form.Item label="Unloading Agency Rate Type" name="Unloading_Rate.Rate_type">
                                <Input readOnly disabled className={classes['disabled-input']} />
                            </Form.Item> */}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Date of Unloading">
                                <Input
                                    readOnly
                                    value={formatDate(unLoadDetails?.attributes?.Date_of_Unloading ?? "")}
                                    name="Date_of_Unloading"

                                />
                            </Form.Item>
                            <Form.Item
                                messageVariables=""
                                label="Owner"
                                onReset={setUnLoadDetails}
                            >
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Vehicle_Number?.data?.attributes?.Owner}
                                    name="Owner"
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Transporting Agency">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Transporting_Agency?.data?.attributes?.Company_name}
                                    name="Transporter_Agency"
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Net Weight at Unloading (Kg)">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Net_Weight ?? "0"}
                                    name="Net_Weight"
                                />
                            </Form.Item>
                            <Form.Item
                                messageVariables=""
                                label="Gross Weight at Unloading (Kg)"
                            >
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Gross_Weight ?? "0"}
                                    name="Gross_Weight"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Difference">
                                <Input
                                    readOnly
                                    value={parseFloat(unLoadDetails?.attributes?.Vehicle_Number?.data?.attributes?.Gross_Weight ?? 0) - parseFloat(unLoadDetails?.attributes?.Gross_Weight ?? 0)}
                                    name="Net_Weight"
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Unloading Location">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Unloading_Location ?? ""}
                                    name="Unloading_Location"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Remarks">
                                <Input
                                    readOnly
                                    value={unLoadDetails?.attributes?.Remarks ?? ""}
                                    name="Remarks"
                                />
                            </Form.Item>
                            {/* <Form.Item label="Unloading Agency Rate" name="Unloading_Rate.Rate">
                                <Input type="number" />
                            </Form.Item> */}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={12} md={12} xs={12}>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-space"]}
                    >
                        <Grid item lg={4} md={4} xs={12}>
                            Created By: <b>{unLoadDetails?.attributes?.Created_By?.data?.attributes?.Name ?? "NA"}</b>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            Last Edited By: <b>{unLoadDetails?.attributes?.Updated_By?.data?.attributes?.Name ?? "NA"}</b>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12} container spacing={3}>
                            <Grid item lg={12} md={12}>
                                {
                                    unLoadDetails?.attributes?.Reviewed_By?.data?.id
                                        ?
                                        <>
                                            Reviewed By:<b> {unLoadDetails?.attributes?.Reviewed_By?.data?.attributes?.Name ?? "NA"}</b>
                                        </>
                                        :
                                        ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                    >
                        <Grid item lg={12} md={12} xs={12}>
                            <CustomUpload
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                name="Unloading_Images"
                            >
                                {fileList.length >= maxNoOfUploadsAllowed}
                            </CustomUpload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                width={800}
                                footer={null}
                                style={{ height: '500px' }}
                                onCancel={handleCancel}
                            >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                        </Grid>
                    </Grid>
                </Form>
            </div >
        </>
    );
};
