import React, { useState, useEffect } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import {
    Form,
    Select,
    DatePicker,
    Drawer,
    Input,
    Popconfirm,
    notification,
    Spin,
    Tooltip,
    Pagination,
    Row,
    Col,
    Checkbox,
} from "antd";
import {
    EditOutlined,
    ExportOutlined,
    FilterOutlined,
    SearchOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import classes from "./GeneratedLoadingBills.module.scss";
import { Link } from "react-router-dom";
import loadingAgency from "../../../../assets/images/BillsIcons/loadingblue.svg";
import BillSummaryImg from "../../../../assets/images/BillsIcons/billsummary.svg";
import weightHsdImg from "../../../../assets/images/BillsIcons/weighthsd.svg";
import amountTaxImg from "../../../../assets/images/BillsIcons/amounttax.svg";
import constantsArr from "../../../../constants/global-constants";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

export const GeneratedLoadingBills = () => {
    const [filterForm] = Form.useForm();
    const params = new URLSearchParams(document.location.search)
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const [loadingAgencyDropDown, setLoadingAgencyDropDown] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE];
    const [billNumberCount, setBillNumberCount] = useState(0);
    const [tripCount, setTripCount] = useState(0);
    const [workEffectiveRate, setWorkEffectiveRate] = useState(0);
    const [hsdEffectiveRate, setHSDEffectiveRate] = useState(0);
    const [updatedDieselAmountRatio, setUpdatedDieselAmountRatio] = useState(0);
    const [unloadingWeight, setUnloadingWeight] = useState(0);
    const [roundOffWeight, setRoundOffWeight] = useState(0);
    const [totalUnloadingWeight, setTotalUnloadingWeight] = useState(0);
    const [hsdlitre, setHsdLitre] = useState(0);
    const [roundOffHsdLitre, setRoundOffHsdLitre] = useState(0);
    const [totalHsdLitre, setTotalHsdLitre] = useState(0);
    const [workAmount, setWorkAmount] = useState(0);
    const [hsdAmount, setHsdAmount] = useState(0);
    const [gst, setGst] = useState(0);
    const [tds, setTds] = useState(0);
    const [otherDeduction, setOtherDeduction] = useState(0);
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("");
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("");
    const [rangePickerStartDateDefaultValue, setRangePickerStartDateDefaultValue] = useState("");
    const [rangePickerEndDateDefaultValue, setRangePickerEndDateDefaultValue] = useState("");
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT;
    const dateFormatDayjsConversion = constantsArr.GLOBAL_DATE_FORMAT_DAYJS;
    const [generatedBillsTableData, setgeneratedBillsTableData] = useState([]);
    const [totalPayableAmount, setTotalPayableAmount] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    const [currentPagePagination, setCurrentPagePagination] = useState(1)
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const loadingGeneratedBillScreenSelectedAgency = AppHelper.decryptText(sessionStorage.getItem('loading_generated_bill_screen_selected_agency'))
    const loadingGeneratedBillScreenFilterValues = AppHelper.decryptText(sessionStorage.getItem('loading_generated_bills_filter_values'))
    const loadingGeneratedBillScreenSelectedSortOrder = AppHelper.decryptText(sessionStorage.getItem('loading_generated_bills_selected_sort_order'))
    const [hsdRoundOffCheckbox, setHSDRoundOffCheckBox] = useState(false)
    const [wtRoundOffCheckbox, setwtRoundOffCheckbox] = useState(false)
    const [otherDeductionCheckbox, setOtherDeductionCheckbox] = useState(false)
    const [defaultHSDRoundOffCheckBoxStatus, setDefaultCheckHSDRoundOffStatus] = useState(false)
    const [defaultWtRoundOffCheckBoxStatus, setDefaultCheckWtRoundOffStatus] = useState(false)
    const [defaultOtherDeductionCheckBoxStatus, setDefaultOtherDeductionCheckBoxStatus] = useState(false)
    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false);

    const handleSearch = (e) => console.log("search", e.target.value);

    const getGeneratedLoadingBills = (agenciesList, pagination, currentPage, pageSize, filterArgs = {}, sortOrder) => {
        const loadingGeneratedBillScreenSelectedAgency = AppHelper.decryptText(sessionStorage.getItem('loading_generated_bill_screen_selected_agency'))
        let agencyToPass = ((loadingGeneratedBillScreenSelectedAgency && loadingGeneratedBillScreenSelectedAgency !== "") ? loadingGeneratedBillScreenSelectedAgency : null)
        const agencyByName = AppHelper.findAgencyIdByNameProject(agenciesList, agencyToPass, sessionGlobalValues?.projectGlobal)
        AppHelper.getGeneratedLoadingAgency(agencyByName?.id, pagination, currentPage, pageSize, filterArgs, sortOrder).then(response => {
            setGlobalCountItems(response);
            setShowPagination(true)
        }).catch(err => {
            setShowPagination(false)
            notification.error({
                message: "Error while fetching loading generated bills",
                description: err?.message || "Unknown error",
                duration: 3,
            });
        });
    }

    const changeSortOrder = (e) => {
        sessionStorage.setItem("loading_generated_bills_selected_sort_order", AppHelper.encryptText(e.target.value))
        setCurrentPagePagination(1)
        getGeneratedLoadingBills(agencies, true, 1, pageSize, loadingGeneratedBillScreenFilterValues, e.target.value);
    }

    const handleChangeHSDRoundOffCheckbox = (e) => {
        setHSDRoundOffCheckBox(e.target.checked)
        setDefaultCheckHSDRoundOffStatus(e.target.checked)
    }

    const handleChangeWtRoundOffCheckbox = (e) => {
        setwtRoundOffCheckbox(e.target.checked)
        setDefaultCheckWtRoundOffStatus(e.target.checked)
    }

    const handleChangeOtherDeductionCheckbox = (e) => {
        setOtherDeductionCheckbox(e.target.checked)
        setDefaultOtherDeductionCheckBoxStatus(e.target.checked)
    }

    const changePageNumber = (currentPage, pageSize, newSorting = null) => {
        // check page no is displaying in url then set that page no otherwise default 1
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        getGeneratedLoadingBills(agencies, true, currentPage, pageSize, loadingGeneratedBillScreenFilterValues, ((loadingGeneratedBillScreenSelectedSortOrder && loadingGeneratedBillScreenSelectedSortOrder !=="") ? loadingGeneratedBillScreenSelectedSortOrder : 'date_of_billing:desc'));
    };

    const exportLoadingGeneratedBills = async (billId) => {
        const exportResponse = await AppHelper.getGeneratedLoadingAgencyById(billId, true, currentPage, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE)
        if (exportResponse?.error) {
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Vehicle List", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: 'S.NO.', key: "sl_no", width: 8 },
                { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                { header: 'Date of Unloading', key: "Date_of_Unloading", width: 20 },
                { header: 'Challan No.', key: "Challan_Number", width: 15 },
                { header: 'Vehicle No.', key: "Vehicle_Number", width: 15 },
                { header: 'Kanta Slip No', key: "Kanta_Slip_No", width: 15 },
                { header: 'Net Wt. UL (MT)', key: "Net_Weight", width: 15 },
                { header: 'Work Rate', key: "Work_Rate", width: 15 },
                { header: 'Work Amt.', key: "Work_Amount", width: 15 },
                { header: 'D/O No', key: "DO_No", width: 15 },
                { header: 'HSD (LTR)', key: "HSD_LTR", width: 15 },
                { header: 'HSD Rate', key: "Rate_INR", width: 15 },
                { header: 'HSD Amt', key: "HSD_Amt", width: 15 },
            ]

            exportResponse?.data.forEach((billData, key) => {
                billData.attributes.sl_no = key + 1
                billData.attributes.Date_of_Loading = (billData?.attributes?.Date_of_Loading && billData?.attributes?.Date_of_Loading !== "" && billData?.attributes?.Date_of_Loading !== '-') ? dayjs(billData?.attributes?.Date_of_Loading).format(dateFormat) : '-'
                billData.attributes.Date_of_Unloading = (billData?.attributes?.Date_of_Unloading && billData?.attributes?.Date_of_Unloading !== "" && billData?.attributes?.Date_of_Unloading !== '-') ? dayjs(billData?.attributes?.Date_of_Unloading).format(dateFormat) : '-'
                workSheetPendingBills.addRow(billData?.attributes)
            })

            // preapare sheet for global counts

            const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Bill Details")
            // prepare sheet columns / headers
            workSheetPendingBillGlobalCounts.columns = [
                { header: 'TP', key: "first_value", width: 25 },
                { header: exportResponse?.meta?.billResults?.Project?.Thermal_Plant?.Name, key: "second_value", width: 25 },
            ]

            // prepare sheet rows to display
            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Project", second_value: exportResponse?.meta?.billResults?.Project?.Name_of_project ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Agency", second_value: exportResponse?.meta?.billResults?.agency?.Company_name ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Work Type", second_value: 'Loading' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Bill Date", second_value: exportResponse?.meta?.billResults?.date_of_billing ? dayjs(exportResponse?.meta?.billResults?.date_of_billing).format(dateFormat) : '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Period", second_value: `${dayjs(exportResponse?.meta?.minLoadingDate).format(dateFormat)} - ${dayjs(exportResponse?.meta?.maxLoadingDate).format(dateFormat)}` }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Billing Prefix", second_value: exportResponse?.meta?.billResults?.billing_prefix ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Bill No.", second_value: exportResponse?.meta?.billResults?.bill_number ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Trip Count", second_value: exportResponse?.meta?.billResults?.trip_count ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Unloading Wt (MT)", second_value: exportResponse?.meta?.billResults?.unloading_weight ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Round off (WT)", second_value: exportResponse?.meta?.billResults?.round_off_weight ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Total Unload wt (MT)", second_value: exportResponse?.meta?.billResults?.updated_quantity_weight ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Work Amount (Rs.)", second_value: exportResponse?.meta?.billResults?.work_amount ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Work Effective Rate (Rs.)", second_value: exportResponse?.meta?.billResults?.work_effective_rate ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "GST", second_value: exportResponse?.meta?.billResults?.gst ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "HSD Ltr", second_value: exportResponse?.meta?.billResults?.hsd_litre ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "HSD Ltr Round off", second_value: exportResponse?.meta?.billResults?.round_off_hsd ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Total HSD Wt (Ltr)", second_value: exportResponse?.meta?.billResults?.updated_quantity_litre ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "HSD Effective Rate (Rs.)", second_value: exportResponse?.meta?.billResults?.hsd_effective_rate ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "HSD Amount (Rs.)", second_value: exportResponse?.meta?.billResults?.hsd_amount ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Updated Diesel Amount Ratio", second_value: exportResponse?.meta?.billResults?.updated_diesel_amount_ratio ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Other Deduction", second_value: exportResponse?.meta?.billResults?.other_deduction ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "TDS", second_value: exportResponse?.meta?.billResults?.less_tds ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Remarks for Deduction", second_value: exportResponse?.meta?.billResults?.remark_for_deduction ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "BILL AMOUNT PAYABLE", second_value: exportResponse?.meta?.billResults?.total_payable_amount ?? '' }
            )


            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `loading_generated_bills_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Loading generated bills data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
        }
    }

    const handleDeleteLoadingAgency = async (loadingAgencyId) => {
        const response = await AppHelper.deleteGeneratedLoadingAgencyById(loadingAgencyId);
        if (response?.error) {
            console.error('Error response: ', response);
            notification.error({
                message: 'Error: Failed to delete loading agency bill.',
                description: response?.error?.message || 'Unknown error',
                duration: 3,
            });
        } else {
            notification.success({
                message: 'Success',
                description: 'Generated Loading agency bill deleted successfully.',
                duration: 3,
            });
            getGeneratedLoadingBills(agencies, true, currentPage, pageSize, loadingGeneratedBillScreenFilterValues, ((loadingGeneratedBillScreenSelectedSortOrder && loadingGeneratedBillScreenSelectedSortOrder !=="") ? loadingGeneratedBillScreenSelectedSortOrder : 'date_of_billing:desc'))
        }
    }

    const filterResults = (formValues) => {
        formValues.date_of_billing = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`;

        if (hsdRoundOffCheckbox)
            formValues.hsdRoundOff = hsdRoundOffCheckbox;

        if (wtRoundOffCheckbox)
            formValues.wtRoundOff = wtRoundOffCheckbox;

        if (otherDeductionCheckbox)
            formValues.otherDeductionCheckbox = otherDeductionCheckbox;

        sessionStorage.setItem('loading_generated_bills_filter_values', AppHelper.encryptText(formValues))
        getGeneratedLoadingBills(agencies, true, currentPage, pageSize, formValues, ((loadingGeneratedBillScreenSelectedSortOrder && loadingGeneratedBillScreenSelectedSortOrder !=="") ? loadingGeneratedBillScreenSelectedSortOrder : 'date_of_billing:desc'))
        setOpen(false);
    };

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "");
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "");
        } else {
            setRangePickerStartDateValue("");
            setRangePickerEndDateValue("");
            setRangePickerStartDateDefaultValue("");
            setRangePickerEndDateDefaultValue("");
        }
    };

    const setGlobalCountItems = (response) => {
        setBillNumberCount(response?.meta?.billNumberCount ?? 0);
        setTripCount(response?.meta?.tripCount ?? 0);
        setWorkEffectiveRate(response.meta?.workEffectiveRate ?? 0);
        setHSDEffectiveRate(response?.meta?.hsdEffectiveRate ?? 0);
        setUpdatedDieselAmountRatio(response?.meta?.updatedDieselAmountRatio ?? 0);
        setUnloadingWeight(response?.meta?.unloadingWeight ?? 0);
        setRoundOffWeight(response?.meta?.roundOffWeight ?? 0);
        setTotalUnloadingWeight(response?.meta?.totalUnloadingWeight ?? 0);
        setHsdLitre(response?.meta?.hsdLitre ?? 0);
        setRoundOffHsdLitre(response?.meta?.roundOffHsdLitre ?? 0);
        setTotalHsdLitre(response?.meta?.totalHsdLitre ?? 0);
        setWorkAmount(response?.meta?.workAmount ?? 0);
        setHsdAmount(response?.meta?.hsdAmount ?? 0);
        setGst(response?.meta?.gst ?? 0);
        setTds(response?.meta?.tds ?? 0);
        setOtherDeduction(response?.meta?.otherDeduction ?? 0);
        setTotalPayableAmount(response?.meta?.totalPayableAmount ?? 0);
        setgeneratedBillsTableData(response);
    };

    const handleChangeAgency = (agency) => {
        sessionStorage.setItem('loading_generated_bill_screen_selected_agency', agency && agency !== "" ? AppHelper.encryptText(agency) : "")
        // find out id of agency by name
        const agencyByName = AppHelper.findAgencyIdByNameProject(agencies, agency, sessionGlobalValues?.projectGlobal)
        
        AppHelper.getGeneratedLoadingAgency(agencyByName?.id, true, currentPage, pageSize, loadingGeneratedBillScreenFilterValues, ((loadingGeneratedBillScreenSelectedSortOrder && loadingGeneratedBillScreenSelectedSortOrder !=="") ? loadingGeneratedBillScreenSelectedSortOrder : 'date_of_billing:desc')).then((response) => {
            setGlobalCountItems(response);
            setShowPagination(true)
        }).catch((err) => {
            setShowPagination(false)
            notification.error({
                message: "Unable to load generated bills data",
                description: "",
                duration: 3,
            });
        });
    };

    const onSearch = (value) => {
        console.log("search:", value);
    };

    const resetFilterForm = (e) => {
        e.preventDefault();
        filterForm.resetFields();
        setRangePickerStartDateValue("");
        setRangePickerStartDateDefaultValue("");
        setRangePickerEndDateValue("");
        setRangePickerEndDateDefaultValue("");
        setHSDRoundOffCheckBox(false)
        setwtRoundOffCheckbox(false)
        setOtherDeductionCheckbox(false)
        setDefaultCheckHSDRoundOffStatus(false)
        setDefaultCheckWtRoundOffStatus(false)
        setDefaultOtherDeductionCheckBoxStatus(false)
        sessionStorage.removeItem('loading_generated_bills_filter_values')
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const findAgencies = async () => {
        return await AppHelper.getAgenciesByProject(sessionGlobalValues.projectGlobal)
    }

    useEffect(() => {

        if (params.get('agency') && params.get('agency') !== '') {
            sessionStorage.setItem('loading_generated_bill_screen_selected_agency', params.get('agency') && params.get('agency') !== "" ? AppHelper.encryptText(params.get('agency')) : "")
        }

        findAgencies().then(response => {
            setAgencies(response)
            setLoadingAgencyDropDown(AppHelper.getAgencyDropDown(response, "Loading", sessionGlobalValues));
            getGeneratedLoadingBills(response, true, currentPage, pageSize, loadingGeneratedBillScreenFilterValues, ((loadingGeneratedBillScreenSelectedSortOrder && loadingGeneratedBillScreenSelectedSortOrder !=="") ? loadingGeneratedBillScreenSelectedSortOrder : 'date_of_billing:desc'));
        })
        // if filter values are stored then display selected values in filter box
        if (loadingGeneratedBillScreenFilterValues && loadingGeneratedBillScreenFilterValues !== "") {
            const dateBilling = loadingGeneratedBillScreenFilterValues?.date_of_billing
            if (dateBilling !== "") {
                let breakDateBilling = dateBilling.split("~")
                if (breakDateBilling.length > 0) {
                    const startDate = breakDateBilling[0]
                    const endDate = breakDateBilling[1]
                    setRangePickerStartDateDefaultValue(startDate && startDate !== "" ? dayjs(dayjs(startDate).format(dateFormatDayjsConversion)) : '')
                    setRangePickerStartDateValue(startDate && startDate !== "" ? dayjs(startDate).format(dateFormatDayjsConversion) : '')
                    setRangePickerEndDateDefaultValue(endDate && endDate !== "" ? dayjs(dayjs(endDate).format(dateFormatDayjsConversion)) : '')
                    setRangePickerEndDateValue(endDate && endDate !== "" ? dayjs(endDate).format(dateFormatDayjsConversion) : '')
                } else {
                    setRangePickerStartDateDefaultValue("")
                    setRangePickerStartDateValue("")
                    setRangePickerEndDateDefaultValue("")
                    setRangePickerEndDateValue("")
                }
            }
            if (loadingGeneratedBillScreenFilterValues?.hsdRoundOff) {
                setHSDRoundOffCheckBox(loadingGeneratedBillScreenFilterValues?.hsdRoundOff)
                setDefaultCheckHSDRoundOffStatus(true)
            }

            if (loadingGeneratedBillScreenFilterValues?.wtRoundOff) {
                setwtRoundOffCheckbox(loadingGeneratedBillScreenFilterValues?.wtRoundOff)
                setDefaultCheckWtRoundOffStatus(true)
            }

            if (loadingGeneratedBillScreenFilterValues?.otherDeductionCheckbox) {
                setOtherDeductionCheckbox(loadingGeneratedBillScreenFilterValues?.otherDeductionCheckbox)
                setDefaultOtherDeductionCheckBoxStatus(true)
            }

            filterForm.setFieldsValue({
                bill_number: loadingGeneratedBillScreenFilterValues?.bill_number
            })
        }
        document.title = "Loading Generated Bills";
    }, []);

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-transport-bills-padding"]}
            >
                <Grid className={classes["transport-agency-bill-number-wrapper"]} container >
                    <Grid container item lg={6} sm={12} md={7} xs={12} className={classes["transport-agency-wrappper"]}>
                        <img src={loadingAgency} alt="Transporting agency" width={48} />
                        <Typography variant="h5" color="#000" fontWeight="bold">
                            Loading Agency
                        </Typography>
                        <Form>
                            <Select
                                showSearch
                                allowClear
                                placeholder="All"
                                defaultValue={((loadingGeneratedBillScreenSelectedAgency) ? loadingGeneratedBillScreenSelectedAgency : params.get('agency'))}
                                optionFilterProp="children"
                                onChange={(e) => handleChangeAgency(e)}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                className={classes["select-new-entry"]}
                                options={loadingAgencyDropDown}
                            />
                        </Form>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} className={classes["loading-agency-sort-order"]}>
                        <Form>
                            <Form.Item
                                label="Sort By"
                                className={classes["select-new-entry"]}
                            >
                                <Select defaultValue={loadingGeneratedBillScreenSelectedSortOrder && loadingGeneratedBillScreenSelectedSortOrder !== "" ? loadingGeneratedBillScreenSelectedSortOrder : 'date_of_billing:desc'} className={classes["select-button-height"]} id="sortOrder" onChange={(e) => changeSortOrder({ target: { id: "sortOrder", value: e } })}>
                                    <Select.Option value="date_of_billing:desc">Bill Date. (desc)</Select.Option>
                                    <Select.Option value="date_of_billing:asc">Bill Date. (asc)</Select.Option>
                                    <Select.Option value="agency_name:desc">Agency Name (desc)</Select.Option>
                                    <Select.Option value="agency_name:asc">Agency Name (asc)</Select.Option>
                                    <Select.Option value="bill_number:desc">Bill No (desc)</Select.Option>
                                    <Select.Option value="bill_number:asc">Bill No (asc)</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12} className={classes["transport-agency-bill-number"]}>
                        <UIButton
                            variant="outlined"
                            className={classes["hr-transport-box"]}
                            onClick={showDrawer}
                            startIcon={<FilterOutlined />}
                        >
                            Filters
                        </UIButton>
                        <Drawer title="Filters" onClose={onClose} open={open}>
                            <Form
                                layout="vertical"
                                form={filterForm}
                                onFinish={filterResults}
                            >
                                <Form.Item label="Bill No." name="bill_number">
                                    <Input
                                        placeholder="Enter Bill No."
                                        onChange={handleSearch}
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Date Billing" name="date_of_billing">
                                    <RangePicker
                                        style={{ width: "100%" }}
                                        picker="date"
                                        defaultValue={rangePickerStartDateDefaultValue && rangePickerStartDateDefaultValue !== "" ? [rangePickerStartDateDefaultValue, rangePickerEndDateDefaultValue] : ""}
                                        onChange={handleRangePicker}
                                        format={dateFormat}
                                    />
                                </Form.Item>
                                <Row gutter={[24, 24]} wrap={true}>
                                    <Col span={24}>
                                        <Form.Item
                                            messageVariables=""
                                            wrapperCol={{ span: 30 }}
                                            labelCol={{ span: 30 }}
                                            className={classes['ant-form-item-radio']}
                                        >
                                            <Row gutter={[24, 24]} wrap={true}>
                                                <Col span={12}>
                                                    <Checkbox value={1} onChange={handleChangeHSDRoundOffCheckbox} checked={defaultHSDRoundOffCheckBoxStatus}>HSD Round off</Checkbox>
                                                </Col>
                                                <Col span={12}>
                                                    <Checkbox value={2} onChange={handleChangeWtRoundOffCheckbox} checked={defaultWtRoundOffCheckBoxStatus}>Wt Round off</Checkbox>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]} wrap={true}>
                                    <Col span={24}>
                                        <Form.Item
                                            messageVariables=""
                                            wrapperCol={{ span: 30 }}
                                            labelCol={{ span: 30 }}
                                            className={classes['ant-form-item-radio']}
                                        >
                                            <Row gutter={[24, 24]} wrap={true}>
                                                <Col span={24}>
                                                    <Checkbox value={true} name="OTHER_DEDUCTION_CHECKBOX" onChange={handleChangeOtherDeductionCheckbox} checked={defaultOtherDeductionCheckBoxStatus}>Other Deduction</Checkbox>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className={classes["search-button-area"]}>
                                    <UIButton
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={resetFilterForm}
                                    >
                                        Reset
                                    </UIButton>
                                    <UIButton
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes["submit-button"]}
                                    >
                                        Apply
                                    </UIButton>
                                </div>
                            </Form>
                        </Drawer>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-transport-bills-padding"]}
            >
                <Grid className={classes["middle-boundary-transport"]} container>
                    <Grid>
                        {/* Will change in future dynamically */}
                        <Typography
                            variant="h5"
                            color="#fff"
                            className={classes["text-boundary-tranport"]}
                        >
                            {loadingGeneratedBillScreenSelectedAgency}
                        </Typography>
                    </Grid>
                    <Grid className={classes["total-amount-transport"]}>
                        <Typography variant="h6" fontWeight="bold" color="#fff">
                            Total Payable Amount
                        </Typography>
                        <Typography variant="h6" color="#fff" fontWeight="bold">
                            = &nbsp; &#8377; {totalPayableAmount.toLocaleString("en-IN")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-transport-bills-padding"]}
            >
                <Grid container spacing={0} className={classes["summary-wrapper"]}>
                    <Typography variant="body1" className={classes["summary-text"]}>
                        Transaction Summary
                    </Typography>
                    <Grid className={classes["line1"]}></Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-transport-bills-padding"]}
            >
                <Grid className={classes["transaction-detail-wrapper"]}>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="#353535"
                        display="flex"
                    >
                        <img src={BillSummaryImg} alt="transaction details" />
                        &nbsp;&nbsp;&nbsp;Bill Summary: Rates and diesel Amount Ratio
                    </Typography>

                    <Grid
                        container
                        spacing={0}
                        className={classes["transaction-detail-flex"]}
                    >
                        <Grid className={classes["bottom-transport-first-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {billNumberCount}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Bill No Count
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-first-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {tripCount}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Trip Count
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-first-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {workEffectiveRate}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Work Effective Rate (Rs.)
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-first-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {hsdEffectiveRate}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                HSD Effective Rate (Rs.)
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-first-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {updatedDieselAmountRatio}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Updated Diesel Amount Ratio
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-transport-bills-padding"]}
            >
                <Grid className={classes["transaction-detail-wrapper"]} container>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="#353535"
                        display="flex"
                    >
                        <img src={weightHsdImg} alt="transaction details" />
                        &nbsp;&nbsp;&nbsp;Weight and HSD Details
                    </Typography>

                    <Grid
                        container
                        spacing={0}
                        className={classes["transaction-detail-flex"]}
                    >
                        <Grid className={classes["bottom-transport-second-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {unloadingWeight}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Unloading Wt (MT)
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-second-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {roundOffWeight}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Round Off Wt (MT)
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-second-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {totalUnloadingWeight}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Total Unload Wt (MT)
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-second-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {hsdlitre}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                HSD LTR
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-second-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {roundOffHsdLitre}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Round Off HSD LTR
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-second-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {totalHsdLitre}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Total HSD LTR
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-transport-bills-padding"]}
            >
                <Grid className={classes["transaction-detail-wrapper"]}>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="#353535"
                        display="flex"
                    >
                        <img src={amountTaxImg} alt="transaction details" />
                        &nbsp;&nbsp;&nbsp;Amount and Tax Details
                    </Typography>

                    <Grid
                        container
                        spacing={0}
                        className={classes["transaction-detail-flex"]}
                    >
                        <Grid className={classes["bottom-transport-third-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {workAmount}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Work Amount (Rs.)
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-third-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {hsdAmount}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                HSD Amount (Rs.)
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-third-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {gst}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                GST
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-third-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {tds}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                TDS
                            </Typography>
                        </Grid>
                        <Grid className={classes["bottom-transport-third-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff">
                                {otherDeduction}
                            </Typography>
                            <Typography variant="body1" color="#fff">
                                Other Deduction
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-transport-bills-padding"]}
            >
                <Grid container spacing={0} className={classes["summary-wrapper"]}>
                    <Typography variant="body1" className={classes["summary-text"]}>
                        Bills
                    </Typography>
                    <Grid className={classes["line2"]}></Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-transport-bills-padding"]}
            >
                <Grid
                    item
                    lg={9.8}
                    md={8.5}
                    sm={12}
                    xs={12}
                    container
                    className={classes["bill-number"]}
                    textAlign="center"
                >
                    <Grid item lg={2} md={2} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            Bill Prefix
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            Bill No.
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={3} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            Agency
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            Date of Billing
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={3} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            Bill Amount
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={3} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            Payable Amount
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item lg={2.2} md={3.5} sm={6} xs={12} container>
                    <Grid item lg={4} md={6} sm={4} xs={6}>
                        &nbsp;
                    </Grid>
                    <Grid item lg={4} md={6} sm={4} xs={6}>
                        &nbsp;
                    </Grid>
                    <Grid item lg={4} md={6} sm={4} xs={6}>
                        &nbsp;
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={0.5}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-transport-bills-padding"]}
            >
                {!generatedBillsTableData.data ? (
                    // Show spinner while data is being loaded
                    <Spin size="large" className={classes["generated-spin"]} />
                ) : generatedBillsTableData.data.length === 0 ? (
                    // Show message when data is not available
                    <Typography
                        variant="body1"
                        className={classes["no-generated-bill-margin-auto"]}
                    >
                        No generated records available.
                    </Typography>
                ) : (
                    // Render data if available
                    generatedBillsTableData.data?.map((item) => (
                        <>
                            <Grid
                                item
                                lg={9.8}
                                md={8.5}
                                sm={12}
                                xs={12}
                                container
                                className={classes["bill-number-detail-background"]}
                                textAlign="center"
                                key={item?.id}
                            >
                                <Link
                                    className={classes["view-list-flex-link"]}
                                    to={`/bills/loading/view-transaction-summary/${item?.id}`}
                                >
                                    <Grid item lg={2} md={3} xs={3}>
                                        <Typography
                                            variant="body1"
                                            color="#555555"
                                            fontWeight="bold"
                                        >
                                            {item?.attributes?.billing_prefix ?? '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={3} xs={3}>
                                        <Typography
                                            variant="body1"
                                            color="#555555"
                                            fontWeight="bold"
                                        >
                                            {item?.attributes?.bill_number ?? 0}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={3} xs={3}>
                                        <Typography
                                            variant="body1"
                                            color="#555555"
                                            fontWeight="bold"
                                        >
                                            {item?.attributes?.agency?.data?.attributes?.Company_name ?? '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={3} xs={3}>
                                        <Typography
                                            variant="body1"
                                            color="#555555"
                                            fontWeight="bold"
                                        >
                                            {item?.attributes?.date_of_billing
                                                ? dayjs(item?.attributes?.date_of_billing).format(
                                                    "DD-MM-YYYY"
                                                )
                                                : ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={3} xs={3}>
                                        <Typography
                                            variant="body1"
                                            color="#555555"
                                            fontWeight="bold"
                                        >
                                            {item?.attributes?.total_billed_amount ?? 0}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={3} xs={3}>
                                        <Typography
                                            variant="body1"
                                            color="#555555"
                                            fontWeight="bold"
                                        >
                                            {item?.attributes?.total_payable_amount ?? 0}
                                        </Typography>
                                    </Grid>
                                </Link>
                            </Grid>

                            <Grid
                                item
                                lg={2.2}
                                md={3.5}
                                sm={6}
                                xs={12}
                                container
                                marginBottom="23px"
                                textAlign="right"
                            >
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Tooltip title="Export">
                                        <Link className={classes["bill-number-icons"]} to="#" onClick={() => exportLoadingGeneratedBills(item?.id)}>
                                            <ExportOutlined style={{ color: "#555555" }} />
                                        </Link>
                                    </Tooltip>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Tooltip title="Edit">
                                        <Link className={classes["bill-number-icons"]} to={`/bills/loading/edit-loading-bill/${item?.id}`}>
                                            <EditOutlined style={{ color: "#555555" }} />
                                        </Link>
                                    </Tooltip>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Popconfirm
                                        title="Delete the task"
                                        description="Are you sure you want to delete this Bill? The vehicle list will be moved to Pending bills."
                                        onConfirm={() => handleDeleteLoadingAgency(item?.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Link className={classes["bill-number-icons"]}>
                                            <DeleteOutlined style={{ color: "#ff0000" }} />
                                        </Link>
                                    </Popconfirm>
                                </Grid>
                            </Grid>
                        </>
                    ))
                )}
            </Grid>
            <>
                {
                    showPagination === true && generatedBillsTableData?.data?.length > 0 ? (
                        <div className={classes["pagination-box"]}>
                            <Pagination
                                current={currentPagePagination}
                                showSizeChanger={false}
                                defaultCurrent={currentPage}
                                defaultPageSize={currentPageSizePagination}
                                showTotal={(total) => `Total ${total} items`}
                                responsive
                                pageSize={currentPageSizePagination}
                                onChange={changePageNumber}
                                total={generatedBillsTableData?.meta?.pagination?.total}
                            />
                        </div>
                    ) : (
                        ""
                    )
                }
            </>
        </>
    );
};
