import React, { useEffect, useState } from "react";
import { Form, Table, Input, DatePicker, Switch, notification, Tooltip, Spin } from "antd";
import classes from "./PaymentSummary.module.scss";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import billedBalanceImg from "../../../../assets/images/HeaderIcons/billedImg.svg";
import unbilledBalanceImg from "../../../../assets/images/HeaderIcons/unbilledbalance.svg";
import effBalanceImg from "../../../../assets/images/HeaderIcons/effbalance.svg";
import alreadyPaidImg from "../../../../assets/images/HeaderIcons/alreadypaid.svg";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'
import constantsArr from "../../../../constants/global-constants";

export const PaymentSummary = () => {

    const [paymentSummaryRecords, setPaymentSummaryRecords] = useState([])
    const [grandTotalPaymentToBeMade, setGrandTotalPaymentToBeMade] = useState(0)
    const [inputBoxInLoopValues, setInputBoxInLoopValues] = useState([])
    const [totalBilledBalanceWidget, setTotalBilledBalanceWidget] = useState(0)
    const [totalUnBilledBalanceWidget, setTotalUnBilledBalanceWidget] = useState(0)
    const [totalEffectiveBalanceWidget, setTotalEffectiveBalanceWidget] = useState(0)
    const [totalAlreadyPaidWidget, setAlreadyPaidWidget] = useState(0)
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const [dateInCalendar, setDateInCalendar] = useState(dayjs())
    const [defaultAddPaymentInputValue, setDefaultAddPaymentInputValue] = useState(0)
    const [isHardCopyReceivedCheckBoxOn, setIsHardCopyReceivedCheckBoxOn] = useState(false)
    const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false)
    const [showProgressBar, setShowProgressBar] = useState(true)
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT

    const columns = [
        {
            title: "S.N.",
            dataIndex: "s_no",
            width: "4%",
        },
        {
            title: "Vendor Name",
            dataIndex: "vendor_name",
            width: "10%",
        },
        {
            title: "Billed Payable",
            dataIndex: "billed_payable",
            width: "10%",
        },
        {
            title: "With Held GST",
            dataIndex: "with_held_gst",
            width: "10%",
        },
        {
            title: "Already Paid",
            dataIndex: "already_paid",
            width: "8%",
        },
        {
            title: "Billed Balance",
            dataIndex: "billed_balance",
            width: "9%",
        },
        {
            title: "Unbilled Balance",
            dataIndex: "unbilled_balance",
            width: "9%",
        },
        {
            title: "Eff Balance",
            dataIndex: "eff_balance",
            width: "7%",
        },
        {
            title: "Add Payment",
            dataIndex: "add_payment",
            width: "10%",
            render: (text, record) => (
                <Form>
                    <Input defaultValue={defaultAddPaymentInputValue} className="input_box_amount" type="number" step={0.1} onChange={(e) => calculateSumAmountPayment(record.key, e.target.value)} onKeyPress={allowMaxValuesInputBox} onKeyDown={AppHelper.disableNumberTypeArrowKeys} min={0} max={99999} disabled={isHardCopyReceivedCheckBoxOn} />
                </Form>
            ),
            fixed: "right",
        },
    ];

    const handleHardCopyRecievedChange = (e) => {
        setIsHardCopyReceivedCheckBoxOn(e)
        getPaymentSummaryDetails(e)
    }

    const allowMaxValuesInputBox = (e) => {
        const weightVal = e.target.value
        if (weightVal.toString().length > 10) {
            e.preventDefault();
        } else {
            AppHelper.disableNumberTypeArrowKeys(e)
        }
    }

    const handleDateChange = (e) => {
        setDateInCalendar(e.target.value)
    }

    const exportPaymentSummary = async () => {

        for (let index = 0; index < inputBoxInLoopValues.length; index++) {
            if (inputBoxInLoopValues[index]?.amount && inputBoxInLoopValues[index]?.amount > 0) {
                if (parseFloat(inputBoxInLoopValues[index]?.amount) > parseFloat(inputBoxInLoopValues[index]?.effectiveBalance)) {
                    notification.error({
                        message: 'Error',
                        duration: 3,
                        description: `Amount cannot be greater than effective balance in Row no : ${index + 1}`
                    })
                    return false
                }
            }
        }
        setIsExportButtonDisabled(true)
        const exportResponse = await AppHelper.getPaymentSummaryDetails(isHardCopyReceivedCheckBoxOn)
        if (exportResponse?.error) {
            setIsExportButtonDisabled(false)
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Payment Summary", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })

            let workSheetColumns = [
                { header: 'S.NO.', key: "sl_no", width: 10 },
                { header: 'Vendor Name', key: "vendor_name", width: 25 },
                { header: 'Billed Payable', key: "billed_payable", width: 25 },
                { header: 'GST', key: "gst", width: 20 },
                { header: 'Already Paid', key: "already_paid", width: 25 },
                { header: 'Billed Balance', key: "billed_balance", width: 25 },
                { header: 'Unbilled Balance', key: "unbilled_balance", width: 25 },
                { header: 'Effective Balance', key: "effective_balance", width: 25 },
                { header: 'Date of Payment', key: "date", width: 20 }
            ]

            if (!isHardCopyReceivedCheckBoxOn)
                workSheetColumns.push({ header: 'Add Payment', key: "add_payment", width: 25 })

            workSheetPendingBills.columns = workSheetColumns

            for (let index = 0; index < exportResponse?.data?.attributes?.paymentSummaryRecords.length; index++) {
                let exportData = {}
                exportData.sl_no = index + 1
                exportData.vendor_name = (exportResponse?.data?.attributes?.paymentSummaryRecords[index]?.agencyName && exportResponse?.data?.attributes?.paymentSummaryRecords[index]?.agencyName !== "") ? exportResponse?.data?.attributes?.paymentSummaryRecords[index]?.agencyName : '-'
                exportData.billed_payable = exportResponse?.data?.attributes?.paymentSummaryRecords[index]?.totalBilledPayableAmount ?? 0
                exportData.gst = exportResponse?.data?.attributes?.paymentSummaryRecords[index]?.totalGSTAmount ?? 0
                exportData.already_paid = exportResponse?.data?.attributes?.paymentSummaryRecords[index]?.alreadyPaidPaidAmount ?? 0
                exportData.billed_balance = exportResponse?.data?.attributes?.paymentSummaryRecords[index]?.billedBalance ?? 0
                exportData.unbilled_balance = exportResponse?.data?.attributes?.paymentSummaryRecords[index]?.unBilledBalance ?? 0
                exportData.effective_balance = exportResponse?.data?.attributes?.paymentSummaryRecords[index]?.effectiveBalance ?? 0
                exportData.date = dayjs(dateInCalendar).format(dateFormat)
                
                if (!isHardCopyReceivedCheckBoxOn)
                    exportData.add_payment = parseFloat(inputBoxInLoopValues[index]?.amount) ?? 0
               
                workSheetPendingBills.addRow(exportData)
            }

            const workSheetWidgetInfo = workBook.addWorksheet("Widget Info")

            workSheetWidgetInfo.columns = [
                { header: 'Billed Balance', key: "first_value", width: 25 },
                { header: exportResponse?.data?.attributes?.totalBilledBalance ?? 0, key: "second_value", width: 20 },
            ]

            // prepare sheet rows to display
            workSheetWidgetInfo.addRow(
                { first_value: "Unbilled Balance", second_value: exportResponse?.data?.attributes?.totalUnBilledBalance ?? 0 }
            )

            workSheetWidgetInfo.addRow(
                { first_value: "Eff Balance", second_value: exportResponse?.data?.attributes?.totalEffectiveBalance ?? 0 }
            )

            workSheetWidgetInfo.addRow({
                first_value: "Already Paid", second_value: exportResponse?.data?.attributes?.totalAlreadyPaid ?? 0
            })

            if (!isHardCopyReceivedCheckBoxOn) {
                workSheetWidgetInfo.addRow({
                    first_value: "Total Payment To be Made", second_value: grandTotalPaymentToBeMade ?? 0
                })
            }

            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `payment_summary_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Payment summary data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
            setIsExportButtonDisabled(false)
        }
    }

    const savePayment = () => {
        // find out any empty value while saving payment and restrict to submit the form
        const findNonZeroValuesInAddPayment = inputBoxInLoopValues?.find(item => item?.amount > 0)
        console.log(inputBoxInLoopValues, 'inputBoxInLoopValues');

        if (findNonZeroValuesInAddPayment) {
            // save payment details of all non zero payment values
            const payloadDataToSave = []
            for (let index = 0; index < inputBoxInLoopValues.length; index++) {
                if (inputBoxInLoopValues[index]?.amount && inputBoxInLoopValues[index]?.amount > 0) {
                    if (parseFloat(inputBoxInLoopValues[index]?.amount) > parseFloat(inputBoxInLoopValues[index]?.effectiveBalance)) {
                        notification.error({
                            message: 'Error',
                            duration: 3,
                            description: `Amount cannot be greater than effective balance in Row no : ${index + 1}`
                        })
                        return false
                    } else {
                        payloadDataToSave.push({
                            date: dateInCalendar ? dayjs(dateInCalendar).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
                            time: dayjs().format("HH:mm:ss"),
                            amount_paid: inputBoxInLoopValues[index]?.amount,
                            agency: { id: inputBoxInLoopValues[index]?.agency },
                            project: { id: sessionGlobalValues?.projectGlobal }
                        })
                    }
                }
            }
            if (payloadDataToSave?.length === 0) {
                notification.error({
                    message: 'Error',
                    duration: 3,
                    description: "Empty payment data provided to be saved"
                })
                return false
            } else {
                AppHelper.savePayment(payloadDataToSave).then(response => {
                    getPaymentSummaryDetails()
                    setGrandTotalPaymentToBeMade(0)
                    notification.success({
                        message: 'Success',
                        duration: 3,
                        description: 'Payment details have been added successfully'
                    })
                }).catch(err => {
                    notification.error({
                        message: 'Error',
                        duration: 3,
                        description: err?.message
                    })
                })
            }
        } else {
            notification.error({
                message: 'Error',
                duration: 3,
                description: 'Please enter amount to add payment'
            })
        }
    }

    const loadTableRecords = (records) => {
        const data = [];
        const amountArr = []
        for (let i = 0; i < records?.length; i++) {
            let effectiveBalanceCalc = ((records[i]?.effectiveBalance && records[i]?.effectiveBalance !== '' && records[i]?.effectiveBalance !== 0) ? records[i]?.effectiveBalance : 0)
            data.push({
                key: i,
                s_no: `${i + 1}`,
                vendor_name: ((records[i]?.agencyName && records[i]?.agencyName !== '') ? records[i]?.agencyName : ''),
                billed_payable: ((records[i]?.totalBilledPayableAmount && records[i]?.totalBilledPayableAmount !== '' && records[i]?.totalBilledPayableAmount !== 0) ? records[i]?.totalBilledPayableAmount.toLocaleString('en-in') : 0),
                with_held_gst: ((records[i]?.totalWithHeldGSTAmount && records[i]?.totalWithHeldGSTAmount !== '' && records[i]?.totalWithHeldGSTAmount !== 0) ? records[i]?.totalWithHeldGSTAmount.toLocaleString('en-in') : 0),
                already_paid: ((records[i]?.alreadyPaidPaidAmount && records[i]?.alreadyPaidPaidAmount !== '' && records[i]?.alreadyPaidPaidAmount !== 0) ? records[i]?.alreadyPaidPaidAmount.toLocaleString('en-in') : 0),
                billed_balance: ((records[i]?.billedBalance && records[i]?.billedBalance !== '' && records[i]?.billedBalance !== 0) ? records[i]?.billedBalance.toLocaleString('en-in') : 0),
                unbilled_balance: ((records[i]?.unBilledBalance && records[i]?.unBilledBalance !== '' && records[i]?.unBilledBalance !== 0) ? records[i]?.unBilledBalance.toLocaleString('en-in') : 0),
                eff_balance: effectiveBalanceCalc.toLocaleString('en-in'),
                add_payment: "",
            });
            amountArr.push({ index: i, effectiveBalance: effectiveBalanceCalc, amount: 0, agency: ((records[i]?.agencyId && records[i]?.agencyId !== '') ? records[i]?.agencyId : '') })
        }
        setInputBoxInLoopValues(amountArr)
        return data;
    }

    const calculateSumAmountPayment = (index, inputValue) => {
        const updatedAmount = inputBoxInLoopValues.map(item => {
            if (item.index === index) {
                inputValue = parseFloat(inputValue)
                return { ...item, amount: inputValue || 0 }
            }

            return item
        })
        setInputBoxInLoopValues(updatedAmount)
        // calculate sum of amount and place to grand total amount
        let sumOfAmount = updatedAmount?.length > 0 ? updatedAmount.reduce((accumulator, item) => accumulator + item?.amount ?? 0, 0) : 0
        sumOfAmount = parseFloat(sumOfAmount.toFixed(2))
        setGrandTotalPaymentToBeMade(sumOfAmount)
    }

    const getPaymentSummaryDetails = (hardCopyReceived) => {
        setShowProgressBar(true)
        AppHelper.getPaymentSummaryDetails(hardCopyReceived).then(response => {
            const getTableData = loadTableRecords(response?.data?.attributes?.paymentSummaryRecords)
            setPaymentSummaryRecords(getTableData)
            setShowProgressBar(false)
            setTotalBilledBalanceWidget(response?.data?.attributes?.totalBilledBalance ?? 0)
            setTotalUnBilledBalanceWidget(response?.data?.attributes?.totalUnBilledBalance ?? 0)
            setTotalEffectiveBalanceWidget(response?.data?.attributes?.totalEffectiveBalance ?? 0)
            setAlreadyPaidWidget(response?.data?.attributes?.totalAlreadyPaid ?? 0)
            setDefaultAddPaymentInputValue(0)
        }).catch(err => {
            setShowProgressBar(false)
            notification.error({
                message: "Error",
                description: err?.message,
                duration: 3
            })
        })
    }

    useEffect(() => {
        getPaymentSummaryDetails()
    }, [])

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["payment-padding"]}
            >
                <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    spacing={1.5}
                    padding="10px 0"
                    className={classes["header-scroll-horizontal"]}
                >
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-first"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={billedBalanceImg}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Tooltip title={totalBilledBalanceWidget}>
                                        <Typography
                                            variant="body"
                                            color="white"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            {AppHelper.formatNumber(totalBilledBalanceWidget)}
                                        </Typography>
                                    </Tooltip>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Billed Balance
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-second"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={unbilledBalanceImg}
                                        alt="active vehicle"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Tooltip title={totalUnBilledBalanceWidget}>
                                        <Typography
                                            variant="body"
                                            color="white"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            {AppHelper.formatNumber(totalUnBilledBalanceWidget)}
                                        </Typography>
                                    </Tooltip>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Unbilled Balance
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-third"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={effBalanceImg}
                                        alt="In active vehicle"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Tooltip title={totalEffectiveBalanceWidget}>
                                        <Typography
                                            variant="body"
                                            color="white"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            {AppHelper.formatNumber(totalEffectiveBalanceWidget)}
                                        </Typography>
                                    </Tooltip>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Eff Balance
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-fourth"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={alreadyPaidImg}
                                        alt="Hold vehicle"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Tooltip title={totalAlreadyPaidWidget}>
                                        <Typography
                                            variant="body"
                                            color="white"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            {AppHelper.formatNumber(totalAlreadyPaidWidget)}
                                        </Typography>
                                    </Tooltip>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Already Paid
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                className={classes["payment-padding"]}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="body1" color="initial">
                    Hard Copy Recieved <Switch checked={isHardCopyReceivedCheckBoxOn} onChange={handleHardCopyRecievedChange} />
                </Typography>
                <DatePicker defaultValue={dateInCalendar} maxDate={dayjs()} onChange={(e) => handleDateChange({ target: { value: e } })} format="DD-MM-YYYY" allowClear={false} />
            </Grid>

            {
                showProgressBar
                    ?
                    <div className={classes['summary-table-spin-element']}><Spin /></div>
                    :
                    <>
                        <Table
                            className={classes["payment-padding"]}
                            columns={columns}
                            dataSource={paymentSummaryRecords}
                            bordered={true}
                            tableLayout="fixed"
                            scroll={{ x: 1000, y: 520 }}
                            pagination={false}
                            size="small"
                        />
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                            className={classes["payment-padding"]}
                        >
                            <Grid className={classes["total-payment-made"]}>
                                <Typography
                                    variant="body1"
                                    color="#ffffff"
                                    fontWeight="bold"
                                    fontSize="1.3rem"
                                >
                                    Total Payment To be Made
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="#ffffff"
                                    fontWeight="bold"
                                    fontSize="1.3rem"
                                >
                                    &#x20b9; {grandTotalPaymentToBeMade.toLocaleString('en-in')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid className={classes["export-submit-button"]}>
                            <UIButton
                                variant="outlined"
                                className={`${classes["filter-new-entry"]} ${classes["export-button"]} ${isExportButtonDisabled ? classes['button-disabled'] : ''}`}
                                onClick={exportPaymentSummary}
                                disabled={isExportButtonDisabled}
                            >
                                Export
                            </UIButton>
                            <UIButton variant="contained"
                                className={`${isHardCopyReceivedCheckBoxOn ? classes['button-disabled'] : classes["submit-button"]} `}
                                onClick={savePayment}
                                disabled={isHardCopyReceivedCheckBoxOn}>
                                Submit
                            </UIButton>
                        </Grid>
                    </>
            }
        </>
    )

};
