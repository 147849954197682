import React from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

export const ProjectTimelineGraph = ({ timelineChartInfo, projectDetails }) => {

    // Initialize the Gantt module
    // HighchartsGantt(Highcharts);

    // Define the chart options
    // const options = {
    //     chart: {
    //         type: "gantt",
    //     },
    //     yAxis: {
    //         uniqueNames: true,
    //     },
    //     xAxis: {
    //         type: 'datetime'
    //     },
    //     scrollbar: {
    //         enabled: false,
    //     },
    //     lang: {
    //         accessibility: {
    //             axis: {
    //                 xAxisDescriptionPlural:
    //                     "The chart has a two-part X axis " +
    //                     "showing time in both week numbers and days.",
    //                 yAxisDescriptionPlural:
    //                     "The chart has one Y axis showing " + "task categories.",
    //             },
    //         },
    //     },
    //     series: [
    //         {
    //             name: "Project 1",
    //             data: [
    //                 {
    //                     start: Date.UTC(2017, 11, 1),
    //                     end: Date.UTC(2018, 1, 2),
    //                     completed: {
    //                         amount: 0.95,
    //                     },
    //                     name: "Total days as per PO",
    //                 },
    //                 {
    //                     start: Date.UTC(2018, 1, 2),
    //                     end: Date.UTC(2018, 11, 5),
    //                     completed: {
    //                         amount: 0.5,
    //                     },
    //                     name: "Working till today",
    //                 },
    //                 {
    //                     start: Date.UTC(2018, 11, 8),
    //                     end: Date.UTC(2018, 11, 9),
    //                     completed: {
    //                         amount: 0.15,
    //                     },
    //                     name: "Remaining Days",
    //                 },
    //                 {
    //                     start: Date.UTC(2018, 11, 9),
    //                     end: Date.UTC(2018, 11, 19),
    //                     completed: {
    //                         amount: 0.3,
    //                         fill: "#fa0",
    //                     },
    //                     name: "Tentative Days",
    //                 },
    //                 {
    //                     start: Date.UTC(2018, 11, 10),
    //                     end: Date.UTC(2018, 11, 23),
    //                     name: "Difference",
    //                 }
    //             ],
    //         },
    //     ],
    // };

    const getDaysBetweenDates = (startDate, endDate) => {

        const startDateObj = moment(startDate)
        const endDateObj = moment(endDate)

        let dateDifference = Math.round(endDateObj.diff(startDateObj, 'days', true))

        return Math.floor(dateDifference);
    }

    let lastUnloadingDate

    if (timelineChartInfo?.isProjectCompleted)
        lastUnloadingDate = moment(timelineChartInfo?.lastUnloadingDate).format("DD-MM-YYYY") ?? ""
    else
        lastUnloadingDate = moment().format("DD-MM-YYYY") ?? ""


    let tentativeDaysCalculate = getDaysBetweenDates(moment(timelineChartInfo?.tentativeDaysDateRange?.startDate).endOf('day'), moment(timelineChartInfo?.tentativeDaysDateRange?.endDate).endOf('day'))

    const series = [
        {
            data: [
                {
                    x: `Total days as per PO`,
                    y: [
                        moment(timelineChartInfo?.totalDaysAsPerPODateRange?.startDate).endOf('day').valueOf(),
                        moment(timelineChartInfo?.totalDaysAsPerPODateRange?.endDate).endOf('day').valueOf()
                    ],
                    fillColor: '#0067b6'
                },
                {
                    x: 'Working till today',
                    y: [
                        moment(timelineChartInfo?.workingTillTodayDateRange?.startDate).endOf('day').valueOf(),
                        moment(timelineChartInfo?.workingTillTodayDateRange?.endDate).endOf('day').valueOf()
                    ],
                    fillColor: '#50d3ff'
                },
                {
                    x: `Remaining days (${timelineChartInfo?.remainingDaysCount ?? 0})`,
                    y: [
                        moment(timelineChartInfo?.remainingDaysDateRange?.startDate).endOf('day').valueOf(),
                        moment(timelineChartInfo?.remainingDaysDateRange?.endDate).endOf('day').valueOf()
                    ],
                    fillColor: ((timelineChartInfo?.remainingDaysCount < 0) ? '#e93838' : '#444cf7')    
                },
                {
                    x: `Tentative days (${tentativeDaysCalculate})`,
                    y: [
                        moment(timelineChartInfo?.tentativeDaysDateRange?.startDate).endOf('day').valueOf(),
                        moment(timelineChartInfo?.tentativeDaysDateRange?.endDate).endOf('day').valueOf()
                    ],
                    fillColor: '#dfa803'
                },
                {
                    x: `Difference days (${(timelineChartInfo?.differenceCount ?? 0)}) - ${lastUnloadingDate ? `(${lastUnloadingDate})` : ""}`,
                    y: [
                        moment(timelineChartInfo?.differenceDateRange?.startDate).endOf('day').valueOf(),
                        moment(timelineChartInfo?.differenceDateRange?.endDate).endOf('day').valueOf()
                    ],
                    fillColor: timelineChartInfo?.differenceCount < 0 ? '#e93838' : "#479036"
                }
            ]
        }
    ]

    const options = {
        chart: {
            height: 350,
            type: 'rangeBar',
            margin: {
                left: 200
            },
            zoom: {
                enabled: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                dataLabels: {
                    hideOverflowingLabels: false
                },
                barHeight: '50%',
                borderRadius: 10,
            }
        },
        dataLabels: {
            enabled: true,
            hideOverflowingLabels: false,
            formatter: function (val, opts) {
                var diff = getDaysBetweenDates(val[0], val[1])
                return diff + ((diff > 1) ? ' days' : ' day')
            },
            style: {
                colors: ['#f3f4f5', '#fff'],
                fontSize: '16px'
            }
        },
        xaxis: {
            type: 'datetime',
            position: 'top',
            labels: {
                format: 'dd-MM-yyyy',
            }
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy' // Change this format to your desired one
            }
        },
        yaxis: {
            show: true,
            labels: {
                style: {
                    fontSize: '14px',
                    width: 100,

                },
                maxWidth: 300,
            },
        },
        grid: {
            padding: {
                left: 20,
                right: 10
            },
            row: {
                colors: ['#f3f4f5', '#fff'],
                opacity: 1
            }
        }
    }

    return (
        <>
            {/* <HighchartsReact
                highcharts={Highcharts}
                constructorType={"ganttChart"}
                options={options}
            /> */}
            <ReactApexChart options={options} series={series} type="rangeBar" height={350} />
        </>
    );
};
